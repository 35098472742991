export const EnLan = {
    dir: "ltr",
    besaz: "Besaz",
    forbus: "For business",
    blogs: "Blog",
    forads: "Dynamic Ad Solution",
    aboutus: "About us",
    contactus: "Contact us",
    loginpod: "Podcasters login",
    tophometitle: "Besaz",
    tophometitle2: "A path to transformation",
    tophomedesc: 'Welcome to the world of "Besaz". This is where podcast hosting transforms your income. Our hospitality goes above and beyond; We are proud to provide you with the best tools and technologies.',
    tophomecta1: "Register in the panel",
    tophomecta2: "Migration to Basaz",
    showmore: "Show more",
    footerDesc: `Podkadeh team using modern technologies and full familiarity with the industry the podcast tries to make all the audience and users of this fascinating entertainment. have a pleasant experience using it`,
    footerFast: "Links",
    footerBesaz: "Besaz",
    footerSponser: "Sponcer",
    footerPanel: "Podcaster Panel",
    footerAbout: "About us",
    footerContactus: "Contact us",
    footerCopy: "All the rights of this site belong to Naqshe Ava Podkadeh company.",
    footerSocials: "Podkadeh on social network",
    footerNewsTilte: "Newsletter - Subscribe",
    footerNewsDesc: "Enter your email address to receive the latest news from the world of podcasting, special offers from Podkadeh, and app updates.",
    footerNewsEmail: "Email",
    footerNewsSend: "Send",
    sec2title1: "Why Besaz for",
    sec2title2: "Hosting your podcast?",
    sec2box1title: "Insert dynamic content",
    sec2box1desc: "Podcasters deserve to be rewarded for their hard work, which is why we've mastered the art of embedding dynamic content into your podcast. Our innovative technology maximizes your earning potential. Podkadeh enables this using two methods: dynamic ad placement and sponsored content placement. Say goodbye to sponsorship hassles and say hello to a quick and easy monetization experience. With this feature, you earn money from all your episodes.",
    sec2box2title: "A unique experience in speed and uptime",
    sec2box2desc: "In the world of podcasting, every second counts. Upload and publish your episodes with incredible speed with our hosting. We've invested in state-of-the-art infrastructure and optimized servers to make sure your audience can access your content in the blink of an eye.",
    sec2box3title: "Easy release",
    sec2box3desc: "You can publish your podcast on all podcast platforms through podcast hosting. Once you upload your episodes to our panel, you can publish them on all podcast platforms.",
    sec3title: "podcast with the Besaz panel",
    sec3desc: "Get a complete picture of your podcast's performance.",
    sec3cta: "Register in the panel",
    sec3info1: "Instant statistics of your podcast",
    sec3info2: "Disaggregated statistics of episodes",
    sec4title: "Besaz anywhere in the world",
    sec4desc1: "Available at blazing fast streaming speeds, our feed is also read across all podcasters.",
    sec4desc2: "Feel free to make a podcast!",
    sec4info1: "At least twice as fast as all domestic and foreign competitors in Middle East.",
    sec4info2: "On average, it takes 500 milliseconds for each episode to reach the user.",
    sec4info3: "Experience almost 100% uptime with two infrastructures in Middle East and outside Middle East.",
    sec5title1: "Migration to Basaz",
    sec5desc1: "Migrate to Podkadeh with a few clicks and use our unlimited and free services. The change process is very simple and easy. Your data will not be lost or changed in any way. Your podcast will be available to listeners as soon as it goes live.",
    sec5title2: "Do you have a podcast?",
    sec5inputpodname: "Podcast Name",
    sec5inputemail: "Email",
    sec5inputphone: "Phone number",
    sec5inputfeed: "Feed Address",
    sec5inputcta: "Send an immigration request to Basaz",
    sec5successtitle: "Migration to Basaz",
    sec5successdesc: "The migration of your podcast to Basaz has started. After the migration is completed, we will contact you via email.",
    a1Title: "About Podkadeh",
    a1Desc: "Podkdeh is a comprehensive Persian podcast platform. Podcast is a new member of the media family, which has been very well received in the world. Podcasts ride the wave of technology in the world and are developing rapidly. We at Podkdeh are trying to improve the podcast industry in Middle East should move forward with this wave. To achieve this important goal, we have designed three services: Hear, Build and Be Heard.",
    a2Title: "Besaz",
    a2Desc: "Basaz helps podcast creators to overcome the main problems in the way of creating and publishing podcasts. Also, this service provides accurate statistics and figures regarding the performance of each podcast to the creator in order to improve the quality of podcasts and increase listeners. .",
    a3Title: "Shenideh sho",
    a3Desc: 'Hear is designed with an awareness of the issues and problems in the field of podcast advertising. Through this service, podcast creators and brands can increase their work efficiency and have a good experience of advertising in podcasts. ',
    a4Desc: "Using modern technologies and full familiarity with the podcast industry, the podcast team tries to make all the audience and users of this fascinating entertainment have a pleasant experience using it.",
    cFormTitle: "Contact Podkadeh",
    cFormEmail: "Email address",
    cFormMobile: "Mobile number",
    cFormName: "Name and Surname",
    cFormSubject: "Message subject",
    cFormDesc: "Description",
    cFormSend: "Send message",
}