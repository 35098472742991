import React from "react";
import styled from "styled-components";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import { LogoPodkadeh } from "../../Assets/LogoPodkadeh";

const Styles = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100px;
  gap: 6px;
  --anim-time-1: 2s;
  --anim-delay-1: 0.2s;
  .line  {
    height: 3px;
    background-color: var(--n-text-6);
  }
  .one {
    width: 100%;
    transform: scaleX(0);
    animation: line-fill var(--anim-time-1) 1 forwards;
    animation-delay: calc(0 * var(--anim-delay-1));
  }
  .two {
    width: 60%;
    transform: scaleX(0);
    animation: line-fill var(--anim-time-1) 1 forwards;
    animation-delay: calc(1 * var(--anim-delay-1));
  }
  .three {
    width: 30%;
    transform: scaleX(0);
    animation: line-fill var(--anim-time-1) 1 forwards;
    animation-delay: calc(2 * var(--anim-delay-1));
  }
  @keyframes line-fill {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
  }
  @media screen and (max-width: 1000px) {
    width: 30px;
  }
`;

export const LinesOne = ({ style, className }) => {
  return (
    <Styles style={style} className={className}>
      <div className="line one"></div>
      <div className="line two"></div>
      <div className="line three"></div>
    </Styles>
  );
};
