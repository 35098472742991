import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useOnScreen from '../IsOnScreen';


const StyleCss = styled.div`
    direction: rtl !important;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .title {
        font-size: 14px;
        white-space: nowrap;
        transition: all 5s linear;
    }

`
function AutoAnimationTitle(props) {
    const titleRef = useRef();
    const [titlePos , setTitlePos] = useState(0);
    const [titleAnimation , setTitleAnimation] = useState(false);
    const [transTime , setTransTime] = useState(0)
    const isVisible = useOnScreen(titleRef);
    // useEffect(() => {
    //     setTimeout(beginAnimation, 500)
    // },[isVisible]);
    useEffect(() => {
        if(props.loop){
            setTimeout(() => {
                beginAnimation()
            }, 1000);
        }
    },[]);


    const beginAnimation = () => {
        if(props.title.length > 34 && titlePos === 0 && titleRef.current) {
            setTitleAnimation(true);
            setTransTime((props.title.length) * 0.15)
            setTitlePos(titleRef.current.scrollWidth);
            setTimeout(timeOutFunc,(props.title.length) * 150);
        }
    }
    const timeOutFunc = () => {
        if(titleRef.current){
            setTransTime(0)
            setTitlePos(-titleRef.current.clientWidth);
            setTitleAnimation(false);
            setTimeout(gotoZero, 20);
        }
    }
    const gotoZero = () => {
        if(titleRef.current){
            setTransTime((props.title.length) * 0.15)
            setTitlePos(0);
            setTitleAnimation(true);
            setTimeout(beginAnimation, ((props.title.length) * 150) + 5000);
        }
    }
    return (
        <StyleCss>
            <div className="title" style={{transform: 'translateX('+ titlePos +'px)', transition: 'all linear ' + transTime + 's'}} ref={titleRef}>{props.title}</div>
        </StyleCss>
    );    
}
export default AutoAnimationTitle;