import styled from "styled-components";
import { IconHelp } from "../../Assets/Icons/IconHelp";
const Styles = styled.div`
  position: relative;
  display: block;
  .icon {
    position: relative;
  }
  .text {
    width: 17rem;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    background: white;
    font-size: 0.75rem;
    padding: 0.5rem;
    z-index: 1;
    border: none;
    display: none !important;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
  }

  .icon:hover + .text {
    display: block !important;
    position: absolute;
    bottom: 2.5rem;
    right: -50%;
    display: flex;
    align-items: center;
  }
`;
const Span = styled.span`
  position: relative;
  display: block;
  border-bottom: 1px dotted black;
  margin: auto;
  ::before {
    bottom: -5px;
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background: white;
    z-index: 1;
    right: 50%;
    border-radius: 2px;
    width: 0.625rem;
    height: 0.625rem;
  }
`;
export const Tooltip = ({ ClassTooltip, children, ClassText, style, width, right }) => {
  return (
    <Styles style={style} className={ClassTooltip}>
      <IconHelp className="icon" />
      <Span className={"text " + ClassText} style={{width: width, right: right}}>{children}</Span>
    </Styles>
  );
};
