import React from "react";
import styled from "styled-components";
import { Loading } from "../Loading";
import { Link } from "react-router-dom";
const Styles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.1rem 0;
  &.smaller {
    .text-btn {
      border-radius: 8px;
    }
  }
  .text-btn {
    cursor: pointer;
    width: calc(100%);
    padding: var(--fs-textfield) 1rem;
    border-radius: var(--border-radius-2);
    border: 2px var(--n-text-6) solid;
    outline: none;
    transition: var(--trans-1);
    font-size: var(--fs-textfield);
    background-color: var(--n-primary-10);
    color: var(--n-grey-0);
    font-weight: 800;
    box-shadow: var(--n-shadow-1);
    position: relative;
    top: 0;
    left: 0;
    &.btn-type-1 {
      background-color: var(--n-primary-1-o);
      color: var(--n-primary-1);
      border: 1px solid var(--n-primary-1);
      :hover {
        background-color: var(--n-primary-10);
      }
    }
    &.btn-color-1 {
      background-color: var(--n-primary-11);
    }
    
    &.btn-color-2 {
      background-color: var(--n-primary-12);
    }
    
    :hover {
      box-shadow: var(--n-shadow-1-hover);
      top: 2.5px;
      left: 2.5px;
    }
    :disabled {
      background-color: var(--border-1);
      color: var(--text-5);
      box-shadow: none;
      :hover {
        top: 0;
        left: 0;
      }
    }
  }
  .error {
    display: flex;
    height: 1rem;
    font-size: var(--fs-small);
    .icon {
      text-align: center;
      width: 1rem;
      height: 1rem;
    }
    .text {
      margin: 0 0.2rem 0 0;
    }
  }
  @media screen and (max-width: 1445px) {
    .text-field {
      padding: 0.7rem 1rem;
    }
  }
`;
const StylesLink = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.1rem 0;
  &.smaller {
    .text-btn {
      border-radius: 8px;
    }
  }
  .text-btn {
    cursor: pointer;
    width: calc(100%);
    padding: var(--fs-textfield) 1rem;
    border-radius: var(--border-radius-2);
    border: 2px var(--n-text-6) solid;
    outline: none;
    transition: var(--trans-1);
    font-size: var(--fs-textfield);
    background-color: var(--n-primary-10);
    color: var(--n-grey-0);
    font-weight: 800;
    box-shadow: var(--n-shadow-1);
    position: relative;
    top: 0;
    left: 0;
    &.btn-type-1 {
      background-color: var(--n-primary-1-o);
      color: var(--n-primary-1);
      border: 1px solid var(--n-primary-1);
      :hover {
        background-color: var(--n-primary-10);
      }
    }
    &.btn-color-1 {
      background-color: var(--n-primary-11);
    }
    &.btn-color-2 {
      background-color: var(--n-primary-12);
    }
    :hover {
      box-shadow: var(--n-shadow-1-hover);
      top: 2.5px;
      left: 2.5px;
    }
    :disabled {
      background-color: var(--border-1);
      color: var(--text-5);
      box-shadow: none;
      :hover {
        top: 0;
        left: 0;
      }
    }
  }
  .error {
    display: flex;
    height: 1rem;
    font-size: var(--fs-small);
    .icon {
      text-align: center;
      width: 1rem;
      height: 1rem;
    }
    .text {
      margin: 0 0.2rem 0 0;
    }
  }
  @media screen and (max-width: 1445px) {
    .text-field {
      padding: 0.7rem 1rem;
    }
  }
`;
const StylesLink2 = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.1rem 0;
  &.smaller {
    .text-btn {
      border-radius: 8px;
    }
  }
  .text-btn {
    cursor: pointer;
    width: calc(100%);
    padding: var(--fs-textfield) 1rem;
    border-radius: var(--border-radius-2);
    border: 2px var(--n-text-6) solid;
    outline: none;
    transition: var(--trans-1);
    font-size: var(--fs-textfield);
    background-color: var(--n-primary-10);
    color: var(--n-grey-0);
    font-weight: 800;
    box-shadow: var(--n-shadow-1);
    position: relative;
    top: 0;
    left: 0;
    &.btn-type-1 {
      background-color: var(--n-primary-1-o);
      color: var(--n-primary-1);
      border: 1px solid var(--n-primary-1);
      :hover {
        background-color: var(--n-primary-10);
      }
    }
    &.btn-color-1 {
      background-color: var(--n-primary-11);
    }
    &.btn-color-2 {
      background-color: var(--n-primary-12);
    }
    :hover {
      box-shadow: var(--n-shadow-1-hover);
      top: 2.5px;
      left: 2.5px;
    }
    :disabled {
      background-color: var(--border-1);
      color: var(--text-5);
      box-shadow: none;
      :hover {
        top: 0;
        left: 0;
      }
    }
  }
  .error {
    display: flex;
    height: 1rem;
    font-size: var(--fs-small);
    .icon {
      text-align: center;
      width: 1rem;
      height: 1rem;
    }
    .text {
      margin: 0 0.2rem 0 0;
    }
  }
  @media screen and (max-width: 1445px) {
    .text-field {
      padding: 0.7rem 1rem;
    }
  }
`;
export const TextBtn = ({
  style,
  className,
  type,
  name,
  text,
  onclick,
  disabled,
  loading,
  newType1,
  color,
  href=""
}) => {
  let classes = "text-btn";
  if(newType1) {
    classes+= " btn-type-1"
  }
  switch(color){
    case 1:
      classes+= " btn-color-1";
      break;
    case 2:
      classes+= " btn-color-2"
      break;
    default:
      break;
  }
  if (loading) {
    return (
      <Styles style={style} className={className}>
        <button
          type={type}
          className={classes + ' ' +className}
          name={name}
          onClick={onclick}
          disabled={disabled}
        >
          <Loading />
        </button>
      </Styles>
    );
  }
  if(type === "link"){
    return (
      <StylesLink href={href} style={style} className={className}>
        <button
          type={type}
          className={classes}
          name={name}
          onClick={onclick}
          disabled={disabled}
        >
          {text}
        </button>
      </StylesLink>
    );
  }
  if(type === "link-inside"){
    return (
      <StylesLink2 to={href} style={style} className={className}>
        <button
          type={type}
          className={classes}
          name={name}
          onClick={onclick}
          disabled={disabled}
        >
          {text}
        </button>
      </StylesLink2>
    );
  }
  return (
    <Styles style={style} className={className}>
      <button
        type={type}
        className={classes}
        name={name}
        onClick={onclick}
        disabled={disabled}
      >
        {text}
      </button>
    </Styles>
  );
};
