import React, { useRef } from "react";
import styled from "styled-components";
import World from "../../Assets/new1/world.png"
import { Language } from "../../Language/Language";
import { lang } from "../../App";

const Style = styled.div`
    margin-top: 5rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    position: relative;
    &.home4-ltr {
        .circle-back1 {
            right: calc(22% - 175px);
            left: auto;
        }
    }
    .circle-back1 {
        position: absolute;
        width: 350px;
        height: 350px;
        border-radius: 200px;
        background-color: #C7DFBE;
        top: calc(50% - 175px);
        left: calc(22% - 175px);
        box-shadow: var(--n-shadow-1);
        border: 3px solid var(--n-text-6);
        animation: float-3 7s ease-in-out infinite;
        animation-delay: -3s;
    }
    .section {
        z-index: 1;
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        text-align: center;
        .title-main {
            font-size: 32px;
            font-weight: 900;
        }
        .subtitle-main {
            font-weight: 700;
            color: #5E5E5E;
        }
        .boxes {
            display: flex;
            gap: 1rem;
            position: relative;
            .confety {
                position: absolute;
                width: 40px;
                &.conf-1 {
                    top: -40px;
                    right: -40px;
                    transform: rotate(0);
                }
                &.conf-2 {
                    top: -40px;
                    left: -40px;
                    transform: rotate(270deg);
                }
                &.conf-3 {
                    bottom: -40px;
                    right: -40px;
                    transform: rotate(90deg);
                }
                &.conf-4 {
                    bottom: -40px;
                    left: -40px;
                    transform: rotate(180deg);
                }
            }
            .box {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                width: 33%;
                padding: 1rem 1rem 2rem;
                box-shadow: var(--n-shadow-1);
                border: 3px solid var(--n-text-6);
                border-radius: 4px;
                .title {
                    font-size: 48px;
                    font-weight: 700;
                }
                .desc {
                    font-weight: 700;
                }
            }
        }
    }
    .image {
        z-index: 1;
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
            width: 90%;
        }
    }
    @media screen and (max-width: 1000px) {
        margin-top: 0;
        margin-bottom: 0rem;
        grid-column: 1/15;
        padding: 1rem 1rem 4rem;
        overflow: hidden;
        &.home4-ltr {
            .circle-back1 {
                right: auto;
                left: auto;
            }
        }
        .section {
            width: 100%;
            .boxes {
                flex-direction: column;
                .confety {
                    width: 120px;
                    &.conf-1 {
                        top: -50px;
                    }
                    &.conf-2 {
                        top: -50px;
                    }
                    &.conf-3 {
                        bottom: -60px;
                    }
                    &.conf-4 {
                        bottom: -60px;
                    }
                }
                .box {
                    width: calc(100% - 2rem);
                    flex-direction: row;
                    gap: 1rem;
                    align-items: center;
                    background-color: var(--n-body-2);
                    .title {
                        white-space: nowrap;
                    }
                }
            }
        }
        .image {
            width: 100%;
            justify-content: center;
            margin: 2rem 0;
        }
        .circle-back1 {
            width: 200px;
            height: 200px;
            top: calc(50% - 270px);
            left: calc(50% - 100px);
        }
    }

`
export default function Section4(props) {
    const ref1 = useRef();
    return (
        <Style ref={ref1} className={"home4-"+Language[lang].dir}>
            <div className={"circle-back1"}></div>
            <div className="section">
                <div className="title-main">{Language[lang].sec4title}</div>
                <div className="subtitle-main">{Language[lang].sec4desc1}<br/> {Language[lang].sec4desc2}</div>
                {
                    (window.innerWidth < 1000) ? (
                        <div className="image">
                            <img src={World} alt="world"/>
                        </div>
                    ) : (
                        ""
                    )
                }
                <div className="boxes">
                    <div className="box">
                        <div className="title">2x</div>
                        <div className="desc">{Language[lang].sec4info1}</div>
                    </div>
                    <div className="box">
                        <div className="title">1s {">"}</div>
                        <div className="desc">{Language[lang].sec4info2}</div>
                    </div>
                    <div className="box">
                        <div className="title">99.99%</div>
                        <div className="desc">{Language[lang].sec4info3}</div>
                    </div>
                    <ImageConfety className="confety conf-1"/>
                    <ImageConfety className="confety conf-2"/>
                    <ImageConfety className="confety conf-3"/>
                    <ImageConfety className="confety conf-4"/>
                </div>
            </div>
            {
                (window.innerWidth < 1000) ? (
                    ""
                ) : (
                    <div className="image">
                        <img src={World} alt="world"/>
                    </div>
                )
            }
        </Style>
    )
}


function ImageConfety(props) {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="43" height="35" viewBox="0 0 43 35" fill="none">
            <path d="M35.4848 28.4338L29.1042 20.5535L42.3244 10.7837L20.0154 17.0972L26.3959 24.9775L13.1758 34.7473L35.4848 28.4338Z" fill="#1E1E1E"/>
            <path d="M14.5055 14.0095L8.30851 9.81587L15.6071 0L1.10168 9.30895L7.29863 13.5026L0 23.3185L14.5055 14.0095Z" fill="#1E1E1E"/>
        </svg>
    )
}