export const FaLan = {
    dir: "rtl",
    besaz: "بساز",
    forbus: "برای کسب و کارها",
    blogs: "وبلاگ",
    forads: "",
    aboutus: "درباره ما",
    contactus: "تماس با ما",
    loginpod: "ورود به پنل پادکستر",
    tophometitle: "بساز؛ کاملا رایگان!",
    tophometitle2: "راهی به سوی تحول",
    tophomedesc: 'به دنیای "بساز" خوش آمدید. اینجا، میزبانی پادکست، درآمد شما را متحول می‌کند. مهمان‌نوازی ما فراتر از حد معمول است؛ با افتخار بهترین ابزارها و فناوری‌ها را در اختیار شما قرار می‌دهیم.',
    tophomecta1: "ثبت نام در پنل بساز",
    tophomecta2: "مهاجرت به بساز",
    showmore: "مشاهده بیشتر",
    footerDesc: `تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با صنعت
    پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی جذاب،
    تجربه لذت‌بخشی در استفاده از آن داشته باشند`,
    footerFast: "دسترسی سریع",
    footerBesaz: "بساز",
    footerSponser: "اسپانسر",
    footerPanel: "پنل پادکستر",
    footerAbout: "درباره ما",
    footerContactus: "تماس با ما",
    footerCopy: "تمام حقوق این سایت متعلق به شرکت نقش آوای پادکده است.",
    footerSocials: "پادکده در شبکه اجتماعی",
    footerNewsTilte: "خبرنامه - عضو شوید",
    footerNewsDesc: "برای دریافت آخرین اخبار دنیای پادکست و پیشنهادات ویژه پادکده و اطلاع از بروزرسانی‌های اپلیکیشن ایمیل خود را وارد کنید.",
    footerNewsEmail: "ایمیل",
    footerNewsSend: "ارسال",
    sec2title1: "چرا بساز برای",
    sec2title2: "میزبانی پادکست شما؟",
    sec2box1title: "درج محتوای پویا",
    sec2box1desc: "پادکسترها برای تلاش سخت‌شان شایسته دریافت پاداش هستند، به همین دلیل ما به هنر درج محتوای پویا در پادکست، مسلط شده‌ایم. فناوری نوآورانه ما  پتانسیل درآمدی شما را به اوج می‌رساند. ما این کار را به دو طریق انجام می‌دهیم: درج تبلیغات پویا و درج محتوای اسپانسرشیپ.با دردسرهای اسپانسرشیپ خداحافظی کنید و به یک تجربه درآمدزایی سریع و آسان سلام کنید. با این فیچر از تمام اپیزودهای خود درآمد کسب می‌کنید.",
    sec2box2title: "تجربه‌ای بینظیر در سرعت و آپ‌تایم",
    sec2box2desc: "در دنیای پادکست هر ثانیه مهم است. با میزبانی ما اپیزودهای خود را با سرعتی باور نکردنی آپلود و منتشر کنید. ما روی زیرساخت‌های پیشرفته و سرورهای بهینه سرمایه‌گذاری کرده‌ایم تا مطمئن شویم که مخاطبان در یک چشم برهم زدن به محتوای شما دسترسی دارند.",
    sec2box3title: "انتشار آسان",
    sec2box3desc: "پادکست خود را از طریق میزبانی پادکده می‌توانید در تمام پلتفرم‌های پادکست منتشر کنید. با یک بار آپلود اپیزودهای خود در پنل ما می‌توانید آن‌ها را در تمامی پلتفرم‌های پخش پادکست  منتشر کنید.",
    sec3title: "با پنل پادکستر بساز",
    sec3desc: "یک تصویر کامل از عملکرد پادکست‌تان داشته باشید.",
    sec3cta: "ثبت نام در پنل بساز",
    sec3info1: "آمار لحظه ای پادکست شما",
    sec3info2: "آمار تفکیک شده اپیزودها",
    sec4title: "بساز در تمام نقاط دنیا",
    sec4desc1: "با سرعت پخش بینظیر در دسترس است، همچنین فید ما در تمامی پادکچرها خوانده می‌شود.",
    sec4desc2: "با خیال راحت پادکست بساز!",
    sec4info1: "حداقل دوبرابر سریع تر از تمام رقبای داخلی و خارجی در ایران.",
    sec4info2: "به صورت میانگین ۵۰۰ میلی ثانیه زمان لازم است تا هر اپیزود به دست کاربر برسد.",
    sec4info3: "با دو زیرساخت در ایران و خارج ایران، آپتایم نزدیک به صد درصد را تجریه کنید.",
    sec5title1: "مهاجرت به بساز",
    sec5desc1: "با  چند کلیک به پادکده مهاجرت کنید و از خدمات نامحدود و رایگان ما استفاده کنید. فرآیند تغییر بسیار ساده و آسان است. داده‌های شما به هیچ وجه از دست نمی‌روند و تغییری نخواهند کرد. پادکست شما به محض انتقال در دسترس شنوندگان خواهد بود.",
    sec5title2: "پادکست دارید؟",
    sec5inputpodname: "نام پادکست",
    sec5inputemail: "ایمیل",
    sec5inputphone: "شماره تماس",
    sec5inputfeed: "آدرس فید",
    sec5inputcta: "ارسال درخواست مهاجرت به بساز",
    sec5successtitle: "مهاجرت به بساز",
    sec5successdesc: "مهاجرت پادکست شما به بساز شروع شده. پس از اتمام مهاجرت، از طریق ایمیل با شما در تماس خواهیم بود.",
    a1Title: "درباره پادکده",
    a1Desc: "پادکده یک پلتفرم جامع پادکست فارسی است. پادکست عضو جدید خانواده رسانه است که در دنیا با استقبال زیادی مواجه شده است. پادکست‌ها سوار بر موج تکنولوژی در دنیا به سرعت در حال پیشرفت هستند. ما در پادکده تلاش می‌کنیم تا صنعت پادکست در ایران، همگام با این موج پیشروی کند. برای دستیابی به این هدف مهم ما سه سرویس بشنو، بساز و شنیده شو را طراحی کرده‌ایم.",
    a2Title: "بساز",
    a2Desc: "بساز به سازندگان پادکست کمک می‌کند تا از سد مشکلات اصلی موجود در مسیر ساخت و انتشار پادکست عبور کنند. همچنین این سرویس در جهت بهبود کیفیت پادکست‌ها و افزایش شنوندگان، آمار و ارقام دقیقی نسبت به عملکرد هر پادکست در اختیار سازنده آن می‌گذارد.",
    a3Title: "شنیده شو",
    a3Desc: 'شنیده شو با آگاهی نسبت به مسائل و مشکلات موجود در حوزه تبلیغات در پادکست طراحی شده است. سازندگان پادکست و برندها از طریق این سرویس می‌توانند بازدهی کار خود را افزایش دهند و تجربه مطلوبی از تبلیغات در پادکست داشته باشند. ',
    a4Desc: "تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با صنعت پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی جذاب، تجربه لذت‌بخشی در استفاده از آن داشته باشند.",
    cFormTitle: "تماس با پادکده",
    cFormEmail: "ادرس ایمیل",
    cFormMobile: "شماره تلفن همراه",
    cFormName: "نام و نام خانوادگی",
    cFormSubject: "موضوع پیام",
    cFormDesc: "توضیحات",
    cFormSend: "ارسال پیام",
}