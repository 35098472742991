import React, { useRef } from "react";
import styled from "styled-components";
import arrow from "../../Assets/new1/arrow-right.svg"
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Style = styled.div`
    margin-top: 0;
    margin-bottom: 1rem;
    grid-column: 2/14;
    gap: 2rem;
    position: relative;
    .line {
        z-index: 0;
        position: absolute;
        top: 1.25rem;
        left: calc(50% - 20%);
        height: 3px;
        width: calc(40%);
        background-color: var(--n-text-6);
    }
    .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        :hover {
            .icon {
                box-shadow: var(--n-shadow-1-hover);
                top: 2.5px;
                left: 2.5px;
            }
        }
    }
    .icon {
        position: relative;
        top: 0;
        left: 0;
        z-index: 2;
        width: 2rem;
        height: 2rem;
        border-radius: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--n-shadow-1);
        background: var(--n-grey-05);
        border: 3px solid var(--n-text-6);
        transition: var(--trans-1);
        img {
            width: 24px;
            height: 24px;
            display: block;
        }
    }
    .text {
        font-size: 14px;
        font-weight: 700;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 1rem;
        .line {
            width: calc(100%);
            height: 3px;
            top: 1.25rem;
            left: 0;
        }
        .text {
            display: none;
        }
    }
`
export default function ShowMore(props) {
    const ref1 = useRef();
    const moveDown = (e) => {
        window.scrollTo({
          top: window.innerHeight,
          behavior: "smooth",
        })
    }
    return (
        <Style ref={ref1}>
            <div className="line"></div>
            <div className="btn">
                <div className="icon" onClick={moveDown}>
                    <img src={arrow} alt={"arrow"}/>
                </div>
                <div className="text">{Language[lang].showmore}</div>
            </div>
        </Style>
    )
}