import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyleCss = styled.div`
    position: relative;
    width: 100%;
    .time{
        height: 1.5rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
        /* The slider itself */
    .slider {
        direction: ltr;
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 2px; /* Specified height */
        background: #323130; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.9; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s; /* 0.2 seconds transition on hover */
        transition: opacity .2s;
        :hover {
            opacity: 1; /* Fully shown on mouse-over */
        }
        ::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            width: 1.2rem; /* Set a specific slider handle width */
            height: 1.2rem; /* Slider handle height */
            border-radius: 10rem;
            background: #323130; /* Green background */
            cursor: pointer; /* Cursor on hover */
        }
        ::-moz-range-thumb {
            width: 1.2rem; /* Set a specific slider handle width */
            height: 1.2rem; /* Slider handle height */
            border-radius: 10rem;
            background: #323130; /* Green background */
            cursor: pointer; /* Cursor on hover */
        }
    }
`


function SliderNew(props) {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        if(props.timeElapsed && props.time){
            let Pos = makeTimePercent(props.time, props.timeElapsed)
            setPosition(Pos);
        }
        if(props.timeElapsed === 0){
            setPosition(0);
        }
    }, [props.time,props.timeElapsed]);

    
    const makeTimePercent = (time, timeElapsed) => {
        let percent = (timeElapsed/time) * 1000;
        return percent;
    }


    const changed = (e) => {
        setPosition(e.target.value)
        props.changeFunc(e)
    }

    return (
        <StyleCss>
            <div className="time">
                <div>
                    {props.makeTimes(props.time)}    
                </div>
                <div>
                    {props.makeTimes(props.timeElapsed)}  
                </div>
            </div>
            <input type="range" min="1" max="1000" value={position} className="slider" id="myRange" onChange={changed}/>
        </StyleCss>
    )
}


export default SliderNew;