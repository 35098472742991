export const IconEyeslash = ({ style, className }) => {
  // style={style}
  return (
    <svg
      style={style}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5319 9.47004L9.47188 14.53C8.82188 13.88 8.42188 12.99 8.42188 12C8.42188 10.02 10.0219 8.42004 12.0019 8.42004C12.9919 8.42004 13.8819 8.82004 14.5319 9.47004Z"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.42188 19.5301C9.56187 20.0101 10.7719 20.2701 12.0019 20.2701C15.5319 20.2701 18.8219 18.1901 21.1119 14.5901C22.0119 13.1801 22.0119 10.8101 21.1119 9.40005C20.7819 8.88005 20.4219 8.39005 20.0519 7.93005"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5114 12.7C15.2514 14.11 14.1014 15.26 12.6914 15.52"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.47 14.53L2 22"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0013 2L14.5312 9.47"
        stroke="#323130"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
