import React from 'react';

function SearchSVG(props) {
    return (
        <svg id="search" className="search-svg" data-name="search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>search</title>
            <path className="cls-1" d="M11.44,22.88A11.44,11.44,0,1,1,22.88,11.44,11.46,11.46,0,0,1,11.44,22.88Zm0-21.21a9.77,9.77,0,1,0,9.77,9.77A9.78,9.78,0,0,0,11.44,1.67Z"/>
            <path className="cls-1" d="M23.16,24a.83.83,0,0,1-.59-.25l-2.23-2.23a.85.85,0,0,1,0-1.18.83.83,0,0,1,1.18,0l2.23,2.23a.83.83,0,0,1,0,1.18A.76.76,0,0,1,23.16,24Z"/>
        </svg>

    )
}
export default SearchSVG;
