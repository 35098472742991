import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
  } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const serverAddr = "https://api.podkadeh.com/graphql";
// const serverAddr = "http://localhost:4100/graphql";

const httpLink = createHttpLink({
    uri: serverAddr,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token-pod");
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
              fields: {
                listPodcasts: {
                    keyArgs: false,
                    merge(existing = {}, incoming) {
                        return {
                            ...existing,
                            ...incoming,
                            podcasts: [
                                ...((existing.podcasts) ? existing.podcasts : []),
                                ...((incoming.podcasts) ? incoming.podcasts : [])
                            ]
                        }
                    },
                },
                search: {
                    keyArgs: ["Key"],
                    merge(existing = {}, incoming) {
                        return {
                            ...existing,
                            ...incoming,
                            podcasts: [
                                ...((existing.podcasts) ? existing.podcasts : []),
                                ...((incoming.podcasts) ? incoming.podcasts : [])
                            ]
                        }
                    },
                }
              }
            }
        }
    }),
});

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
