import React from "react";
import styled from "styled-components";
const Style = styled.div`
    grid-column: span 14;
    width: 100%;
    height: 50vh;
    .page-top{
        height: 60vh;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 1000px) {
        grid-column: span 8;
        height: 40vh;
        .page-top{
            height: calc(40vh + 0.7rem);
        }
    }
    @media screen and (max-width: 600px) {
        grid-column: span 8;
        height: 30vh;
        .page-top{
            height: calc(30vh + 0.7rem);
        }
    }

`

export default function TopImage({image, text}) {
    if(image){
        return (
            <Style>
                <div className="page-top">
                    <img src={image} alt="ImgHeadreAboutUs" />
                </div>
            </Style>
        )
    } else {
        return (
            <Style>
                <div className="page-top">
                </div>
            </Style>
        )
    }
    
}