import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GoToTop from "./Components/Helper/GoToTop";
import PageTitles from "./Components/Helper/PageTitles";
import { ModalError } from "./Components/ModalError";
import { Language } from "./Language/Language";
import { Loading } from "./Components/Loading";
import PodcastPage from "./Pages/PodcastPage";
import Podcasts from "./Pages/Podcasts";
import EpisodePage from "./Pages/EpisodePage";

const HomeV2 = lazy(() => import("./Pages/HomeV2"))
const NotMatch = lazy(() => import("./Pages/NotMatch"))
const LandingPageV2 = lazy(() => import("./Pages/LandingPageV2"))
const Besaz = lazy(() => import("./Pages/Besaz"))
const Sponsor = lazy(() => import("./Pages/Sponsor"))
const ContactUs = lazy(() => import("./Pages/ContactUs"))
const TermsOfService = lazy(() => import("./Pages/TermsOfService"))
const AboutUs = lazy(() => import("./Pages/AboutUs"))
const DynamicAds = lazy(() => import("./Pages/DynamicAds"))

export const lang = "fa"

function App() {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    document.documentElement.style.setProperty("--direction", Language[lang].dir);
  })
  function updateError({ text, time, type }) {
    setErrors({
      text: text,
      time: time,
      type: type,
    });
  }
  return (
    
    <BrowserRouter>
      <GoToTop/>
      <PageTitles/>
      <Suspense fallback={<Loading/>}>
      <Routes>
        {/* <Route path="/" element={<CommingSoon updateError={updateError}/>} /> */}
        <Route path="/" element={<HomeV2 updateError={updateError}/>} />
        <Route path="/dynamic-ads" element={<DynamicAds updateError={updateError}/>} />
        <Route path="/besaz" element={<Besaz updateError={updateError}/>} />
        <Route path="/besaz-landing" element={<LandingPageV2 updateError={updateError}/>} />
        {/* <Route path="/Blogs" element={<Blogs />} /> */}
        {/* <Route path="/BlogDetails" element={<BlogDetails updateError={updateError}/>} /> */}
        <Route path="/aboutus" element={<AboutUs updateError={updateError}/>} />
        <Route path="/termsofservice" element={<TermsOfService updateError={updateError}/>} />
        <Route path="/contactus" element={<ContactUs updateError={updateError}/>} />
        <Route path="/sponsor" element={<Sponsor updateError={updateError}/>} />
        <Route path="/podcasts" element={<Podcasts updateError={updateError}/>} />
        <Route path="/podcast/:EnName" element={<PodcastPage updateError={updateError}/>} />
        <Route path="/podcast/:EnName/:Ep_ID" element={<EpisodePage updateError={updateError}/>} />
        <Route path="*" element={<NotMatch updateError={updateError}/>} />
      </Routes>
      </Suspense>
      <ModalError errors={errors} updateError={updateError} />
    </BrowserRouter>
  );
}

export default App;
