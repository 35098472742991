import React from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { IconLogoPodkadeh } from "../../Assets/Icons/IconLogoPodkadeh";
import { IconBesaz } from "../../Assets/Icons/IconBesaz";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
import logoen from "../../Assets/Logo-En.svg"
const Nav = styled.nav`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  background-color: var(--primary-1) !important;
  position: fixed;
  z-index: 700;
  top: 0;
  left: 0;

  .nav-container-right {
    align-items: center;
    grid-column: 2/11;
    justify-content: flex-start;
    display: flex;
    grid-gap: 30px;
    @media screen and (max-width: 1200px) {
      grid-gap: 22px !important;
      //meghdar bala taghir naknd
    }
    a {
      
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      font-size: var(--fs--22-0-0);
      color: var(--text-2);
      text-decoration: none;
      :hover {
        color: #ffffff;
      }
    }
  }
  .nav-container-left {
    grid-column: 12/14;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .item {
      width: 100%;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    svg {
      width: var(--ws--108-94-71);
    }
  }
  .nav-cta {
    height: 34px;
    width: 100%;
    border: 3px solid var(--text-42);
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    @media screen and (max-width: 1200px) {
      width: 190px;
    }
    font-weight: 400;
    line-height: 20px;
    font-size: var(--fs--20-12-0);
    color: var(--text-42);
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    :hover {
      color: #ffffff;
      svg {
        fill: #ffffff;
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    svg {
      width: var(--ws--36-20-20);
      fill: var(--text-42);
      transition: all 0.15s ease-in-out;
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    height: 100%;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1665px) {
    .nav-container-right {
      align-items: center;
      grid-column: 2/11;
      grid-gap: 30px;
    }
    .nav-container-left {
      grid-column: 11/14;
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px !important;
    width: calc(100% - calc(5.65vw * 2));
    padding: 0  5.65vw;
    .container {
      width: 33%;
      display: flex;
    }
    .left {
      justify-content: flex-end;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 94px !important;
        height: 36px !important;
      }
    }
    .downloadinresponsive {
      /* margin-left: 5.65vw; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: transparent;
      padding: 7px 13px;
      border-radius: 0;
      border: 3px solid var(--text-42);
      width: fit-content;
      font-weight: 400;
      line-height: 20px;
      font-size: var(--fs--20-12-0);
      color: var(--text-42);
      text-decoration: none;
      transition: all 0.15s ease-in-out;
      gap: 4px;
      :hover {
        color: #ffffff;
        svg {
          fill: #ffffff;
        }
      }
      svg {
        height: 20px;
        width: 20px;
        /* margin-left: 4px; */
        fill: var(--text-42);
        transition: all 0.15s ease-in-out;
      }
    }
  }
  @media screen and (max-width: 600px) {
    height: 50px !important;
    position: fixed;
    justify-content: space-between;
    z-index: 700;
    top: 0;
    left: 0;
    .downloadinresponsive {
      padding: 5px 5px;
      p {
        display: none;
      }
      svg {
        margin: 0;
      }
    }
    .logo {
      width: auto !important;
      display: flex;
      align-items: center;
      /* margin-left: 30px; */
      svg {
        width: 71px !important;
        height: 28px !important;
      }
    }
    a {
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      color: #e5e5e5;
      text-decoration: none;
      :hover {
        color: #ffffff;
      }
    }
    ul {
      list-style: none;
    }
  }
`;
const Berger = styled.button`
  @media screen and (max-width: 1000px) {
    cursor: pointer;
    border: none;
    background: none;
    /* margin-right: 5.65vw; */
    padding: 7px 5px;
    background-color: transparent;
    border-radius: 0;
    border: 3px solid var(--text-42);
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      background: var(--text-42);
      border-radius: 1px;
      display: block;
      height: 2px;
      width: 20px;
      margin-bottom: 5px;
      :last-child{
        margin-bottom: 0;
      }
    }
  }
`;

let activeStyle = {
  color: "var(--text-42)",
  borderBottom: "var(--text-42) 4px solid",
  borderRadius: "2px",
  height: "calc(100% - 8px)",
  paddingTop: "4px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};
function Navbar(props) {
  if (window.innerWidth > 1000) {
    return (
      <Nav>
        <ul className="nav-container-right">
          <li className="item">
            <Link to="/" className="logo ">
              {
                (Language[lang].dir === "ltr") ? (
                  <img src={logoen} alt="podkadeh" style={{width: "var(--ws--108-94-71)"}}/>
                ) : (
                  <IconLogoPodkadeh />
                )
              }
            </Link>
          </li>
          <li className="item">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/"
            >
              {Language[lang].besaz}
            </NavLink>
          </li>
          <li className="item">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/podcasts"
            >
              آرشیو پادکست
            </NavLink>
          </li>
          <li className="item">
            <a
              href="https://podkadeh.com/blog/"
            >
              {Language[lang].blogs}
            </a>
          </li>
          
          {
            (lang === "fa") ? (
              <li className="item">
                <a
                  href="https://shenidesho.com"
                >
                  {Language[lang].forbus}
                </a>
              </li>
            ) : ""
          }
          {
            (lang === "en") ? (
              <NavLink
                active
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/dynamic-ads"
              >
                {Language[lang].forads}
              </NavLink>
            ) : ""
          }
          
          {/* <li className="item">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/Blogs"
            >
              بلاگ
            </NavLink>
          </li> */}
          <li className="item">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/aboutus"
            >
              {Language[lang].aboutus}
            </NavLink>
          </li>
          <li className="item">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/contactus"
            >
              {Language[lang].contactus}
            </NavLink>
          </li>
          <div style={{flexGrow: 1}}></div>
          {
            (lang === "en") ? (
              <li className="item">
                <a
                  href="https://podkadeh.com"
                >
                  Persian
                </a>
              </li>
            ): ""
          }
        </ul>
        {
            (lang === "fa") ? (
              <ul className=" nav-container-left">
                <li className=" item  ">
                  <a href="https://podkadeh.com/podcaster" className="nav-cta">
                    <IconBesaz/>
                    {Language[lang].loginpod}
                  </a>
                </li>
              </ul>
            ): ""
          }
      </Nav>
    );
  } else {
    return (
      <Nav>
        <div className="container">
          <Berger
            onClick={() =>
              props.sideState ? props.funcSide(false) : props.funcSide(true)
            }
          >
            <span></span>
            <span></span>
            <span></span>
          </Berger>
        </div>
        <Link to="/" className="logo">
          {
            (Language[lang].dir === "ltr") ? (
              <img src={logoen} alt="podkadeh" style={{width: "var(--ws--108-94-71)"}}/>
            ) : (
              <IconLogoPodkadeh />
            )
          }
        </Link>
        <div className="container left">
          {
            (lang === "fa") ? (
              <a href="https://podkadeh.com/podcaster" className="downloadinresponsive">
                <IconBesaz />
                <p>{Language[lang].loginpod}</p>
              </a>
            ): ""
          }
        </div>
      </Nav>
    );
  }
}

export default Navbar;
