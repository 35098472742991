import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useOnScreen from '../IsOnScreen';
const StyleCss = styled.div`
    padding-top: 1px;
`

function EndObserver(props) {
    const endRef = useRef();
    const isVisible = useOnScreen(endRef);
    useEffect(() => {
        if(isVisible){
            props.func(isVisible)
        }
    },[isVisible]);
    

    return (
        <StyleCss ref={endRef}>
        </StyleCss>
    );    
}
export default EndObserver;