import React, { useRef } from "react";
import styled from "styled-components";
import Img1 from "../../Assets/new1/sec2-1.svg"
import Img2 from "../../Assets/new1/sec2-2.svg"
import Img3 from "../../Assets/new1/sec2-3.svg"
import { Language } from "../../Language/Language";
import { lang } from "../../App";

const Style = styled.div`
    margin-top: 9rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    position: relative;
    .circle-back1 {
        position: absolute;
        width: 350px;
        height: 350px;
        border-radius: 200px;
        background-color: #C7DFBE;
        top: calc(50% - 175px);
        left: calc(50% - 175px);
        box-shadow: var(--n-shadow-1);
        border: 3px solid var(--n-text-6);
        animation: float-3 7s ease-in-out infinite;
        animation-delay: -3s;
    }
    .section {
        z-index: 1;
        display: flex;
        .padbox {
            margin-top: -1rem;
            width: 44%;
            font-size: 48px;
            font-weight: 900;
        }
        .box {
            width: calc(56% - 3rem);
            flex-grow: 1;
            border: 3px solid var(--n-text-6);
            border-radius: 4px;
            box-shadow: var(--n-shadow-1);
            background: var(--n-grey-05);
            margin-top: -2rem;
            padding: 2rem 1.5rem 3rem 1.5rem;
            display: flex;
            gap: 1rem;
            .image {
                width: 124px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    width: 100%;
                }
            }
            .data {
                width: calc(100% - 124px - 1rem);
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 0.5rem;
                .title {
                    font-size: 24px;
                    font-weight: 900;
                }
                .desc {
                    font-size: 13px;
                    font-weight: 500;
                    color: var(--n-grey-7)
                }
            }
        }
    }
    .title-box {
        display: none;
    }
    @media screen and (max-width: 1000px) {
    overflow: hidden;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0 1rem 1rem;
        grid-column: 1/15;
        .title-box {
            display: block;
            font-size: 32px;
            font-weight: 900;
            margin: 1rem 0;
        }
        .circle-back1 {
            width: 800px;
            height: 800px;
            left: calc(-100px);
            border-radius: 500px;
        }
        .section {
            .padbox {
                width: 20%;
            }
            .box {
                width: calc(80% - 2rem);
                flex-direction: column;
                margin-top: 0rem;
                padding: 1rem 1rem 2rem 1rem;
                .image {
                    margin: auto;
                }
                .data {
                    width: calc(100%);
                    .title {
                        font-size: 20px;
                    }
                    .desc {
                        font-size: 14px;
                    }
                }
            }
        }
    }

`
export default function Section2(props) {
    const ref1 = useRef();
    return (
        <Style ref={ref1}>
            <div className={"circle-back1"}></div>
            {
                (window.innerWidth < 1000) ? (
                    <div className="title-box">{Language[lang].sec2title1}<br/>{Language[lang].sec2title2}</div>
                ) : (
                    ""
                )
            }
            <div className="section">
                {
                    (window.innerWidth < 1000) ? (
                        <div className="padbox"></div>
                    ) : (
                        <div className="padbox">{Language[lang].sec2title1}<br/>{Language[lang].sec2title2}</div>
                    )
                }
                <div className="box">
                    <div className="image">
                        <img src={Img1} alt="icon"/>
                    </div>
                    <div className="data">
                        <div className="title">{Language[lang].sec2box1title}</div>
                        <div className="desc">{Language[lang].sec2box1desc}</div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="box">
                    <div className="image">
                        <img src={Img2} alt="icon"/>
                    </div>
                    <div className="data">
                        <div className="title">{Language[lang].sec2box2title}</div>
                        <div className="desc">{Language[lang].sec2box2desc}</div>
                    </div>
                </div>
                <div className="padbox"></div>
            </div>
            <div className="section">
                <div className="padbox"></div>
                <div className="box">
                    <div className="image">
                        <img src={Img3} alt="icon"/>
                    </div>
                    <div className="data">
                        <div className="title">{Language[lang].sec2box3title}</div>
                        <div className="desc">{Language[lang].sec2box3desc}</div>
                    </div>
                </div>
            </div>
        </Style>
    )
}