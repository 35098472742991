import React, { useRef } from "react";
import styled from "styled-components";
import Img1 from "../../Assets/new1/panel-pic.png"
import Sh1 from "../../Assets/new1/aboutus-shadow.svg"
import { TextBtn } from "../Inputs/TextBtn";
import linesTop from "../../Assets/new1/toplines.svg"
import { Language } from "../../Language/Language";
import { lang } from "../../App";

const Style = styled.div`
    margin-top: 9rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    position: relative;
    .circle-back1 {
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 200px;
        background-color: #ADD0D8;
        top: -100px;
        left: calc(50% - 250px);
        box-shadow: var(--n-shadow-1);
        border: 3px solid var(--n-text-6);
        animation: float-3 7s ease-in-out infinite;
        animation-delay: -3s;
    }
    &.home3-ltr {
        .circle-back1 {
            right: calc(50% - 250px);
            left: auto;
        }
        .box {
            .image {
                .text {
                    &.text-1 {
                        border-radius: 2rem 2rem 2rem 0 ;
                        right: -1.5rem;
                        left: auto;
                    }
                    &.text-2 {
                        border-radius: 2rem 0 2rem 2rem;
                        bottom: -1.5rem;
                        left: 0rem;
                        right: auto;
                    }
                }
            }
            .lines-top {
                left: calc(25% - 125px);
                right: auto;
            }
        }
    }
    .box {
        width: calc(100% - 3rem - 6px);
        flex-grow: 1;
        border: 3px solid var(--n-text-6);
        border-radius: 4px;
        box-shadow: var(--n-shadow-1);
        background: #C7DFBE;
        margin-top: -2rem;
        padding: calc(2rem - 1px) 1.5rem 3rem 1.5rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;
        .image {
            z-index: 1;
            margin-top: -4rem;
            width: calc(50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
                z-index: 2;
                width: 70%;
                border-radius: 4px;
                box-shadow: var(--n-shadow-2);
            }
            .shadow {
                z-index: 1;
                background-color: #000000;
                border-radius: 1000px;
                width: 80%;
                padding-top: 80%;
                position: absolute;
                bottom: -0.5rem;
                box-shadow: none;
                transform-origin: bottom;
                opacity: 0.3;
                transform: scale(1, 0.07);
            }
            .text {
                z-index: 3;
                padding: 1rem 2rem;
                border-radius: 2rem;
                border: 3px solid var(--n-text-6);
                box-shadow: var(--n-shadow-2);
                background: var(--n-grey-05);
                position: absolute;
                &.text-1 {
                    border-radius: 2rem 2rem 0 2rem;
                    top: -2rem;
                    left: -1.5rem;
                }
                &.text-2 {
                    border-radius: 0 2rem 2rem 2rem;
                    bottom: -1.5rem;
                    right: 0rem;
                }
            }
        }
        .lines-top {
            position: absolute;
            width: 250px;
            right: calc(25% - 125px);
            top: calc(-2rem - 33px);
        }
        .data {
            z-index: 1;
            width: calc(45%);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            border: 3px solid var(--n-text-6);
            border-radius: 4px;
            box-shadow: var(--n-shadow-1);
            background: var(--n-grey-05);
            padding: 2rem 1rem 3rem 1rem;
            .title {
                font-size: 32px;
                font-weight: 900;
            }
            .desc {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                color: var(--n-grey-7)
            }
            .btn {
                margin-top: 1rem;
                width: 50%;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        margin-top: 3rem;
        margin-bottom: 1rem;
        grid-column: 1/15;
        padding: 5rem 1rem 3rem;
        overflow: hidden;
        .circle-back1 {
            display: none;
        }
        &.home3-ltr {
            .box {
                .lines-top {
                    right: calc(46% - 125px);
                    top: calc(3rem - 33px);
                    left: auto;
                }
            }
        }
        .box {
            flex-direction: column;
            width: calc(100% - 2rem - 6px);
            padding: calc(2rem - 1px) 1rem 3rem 1rem;
            .lines-top {
                position: absolute;
                width: 250px;
                right: calc(46% - 125px);
                top: calc(3rem - 33px);
            }
            .data {
                width: calc(100% - 2rem - 6px);
                padding: 2rem 1rem 2rem 1rem;
                text-align: center;
                .btn {
                    margin-top: 1rem;
                    width: 80%;
                    font-size: 16px;
                }
            }
            .image {
                z-index: 1;
                margin-top: 2rem;
                width: calc(100%);
                img {
                    width: 90%;
                }
                .text {
                    padding: 0.5rem 1rem;
                    font-size: 9px;
                    font-weight: 700;
                }
            }
        }

    }
`
export default function Section3(props) {
    const ref1 = useRef();
    return (
        <Style ref={ref1} className={"home3-"+Language[lang].dir}>
            <div className={"circle-back1"}></div>
            <div className="box">
                <img className="lines-top" src={linesTop} alt="lines"/>
                <div className="data">
                    <div className="title">
                        {Language[lang].sec3title}
                    </div>
                    <div className="desc">
                        {Language[lang].sec3desc}
                    </div>
                    <div className="btn">
                    <TextBtn
                        type={"link"}
                        text={Language[lang].sec3cta}
                        // onclick={sendData}
                        loading={false}
                        disabled={false}
                        color={1}
                        href={"https://podkadeh.com/podcaster"}
                    />
                    </div>
                </div>
                <div className="image">
                    <img src={Img1} alt="besaz"/>
                    <div className="shadow"/>
                    <div className="text text-1">{Language[lang].sec3info1}</div>
                    <div className="text text-2">{Language[lang].sec3info2}</div>
                </div>
            </div>
        </Style>
    )
}