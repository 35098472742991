import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";

import EndObserver from "../Components/mainComp/EndObserver";
import { gql, useQuery } from "@apollo/client";
import EpisodeCartList from "../Components/mainComp/EpisodeCartList";

import { useParams } from "react-router";
import { Loading } from "../Components/Loading";
// import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import NotMatch from "./NotMatch";
import { ChangeTitlePage, generateRichSchema, MakeChangeCanonical } from "../Components/Helper/PageTitles";

const Style = styled.div`
  /* min-height: 100vh; */
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  .loading {
    margin: 1rem 0;
    grid-column: 2/14;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .top-part {
    z-index: 0;
    grid-column: 1/15;
    background-color: #B71F38;
    height: 24vw;
    border: 3px solid #1E1E1E;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    &::after{
      z-index: 2;
      content: "";
      position: absolute;
      inset: 0;
      background-color: #00000060;
    }
    img {
      position: absolute;
      inset: -10%;
      width: 120%;
      height: 120%;
      object-fit: cover;
      filter: blur(30px);
    }
  }
  .podcast-title {
    z-index: 2;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    margin-top: -140px;
    background-color: #fff;
    .line {
      height: 2px;
      background-color: #000000;
    }
    .row {
      display: flex;
      gap: 2rem;
      padding: 1rem 2rem;
      align-items: center;
      .image {
        width: 140px;
        aspect-ratio: 1;
        border-radius: 70px;
        overflow: hidden;
        margin-top: calc(-2rem - 40px);
        border: 1px solid #000000;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .data {
        display: flex;
        flex-direction: column;
      }
      
      .title {
        font-size: 1.25rem;
        font-weight: 700;
      }
      .autor {
        font-size: 0.87rem;
        font-weight: 700;
        color: #979797;
      }
      .desc-title {
        font-size: 1rem;
        font-weight: 700;
      }
      .desc {
        font-size: 0.87rem;
        font-weight: 500;
        color: #979797;
      }
      .expand {
        flex-grow: 1;
      }
      .actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .action {
          cursor: pointer;
          text-decoration: none;
          background: #000000;
          color: #fff;
          border-radius: 4px;
          padding: 0.5rem 1rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  .container-1000 {
    margin: 2rem 0 0;
    grid-column: 2/14;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .episodes {
    margin: 2rem 0;
    grid-column: 2/14;
    border: 1px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .title-list {
      padding: 0.5rem 2rem;
      font-size: 1.25rem;
      font-weight: 700;
      border-bottom: 1px solid #000;
    }
  }
  @media screen and (max-width: 850px) {
    .top-part {
      height: 80vw;
    }
    .podcast-title {
      grid-column: 1/15;
      .row {
        flex-direction: column;
        gap: 0.75rem;
        padding: 1rem;
        .expand {
          display: none;
        }
        .data {
          align-self: normal;
          align-items: center;
          gap: 0.25rem;
        }
        .desc-title {
          align-self: normal;
        }
        .desc {
          align-self: normal;
        }
      }
    }
    .container-1000 {
      grid-column: 1/15;
    }
    .episodes {
      grid-column: 1/15;
      .title-list {
        padding: 0.5rem 1rem;
      }
    }
  }
`;


const Podcasts_query = gql`
  query PodcastsEn($En_Name: String!) {
        podcastEn(En_Name: $En_Name){
            Pod_ID
            User_ID
            Cat_Name
            Pr_Name
            En_Name
            title
            author
            imageURL
            visits
            follower
            follow
            description
            imagecache {
              size500
            }
            episodes{
                Ep_ID
                Pod_ID
                Title
                pubDate
                image
            }
        }
    }
`;

export default function PodcastPage(props) {
  const params = useParams()
  const [loadedEp, setLoadedEp] = useState(10);
  const { data, loading, error } = useQuery(Podcasts_query,{
      variables: {
        En_Name: params.EnName,
      }
  });
  useEffect(() => {
    MakeChangeCanonical("https://podkadeh.com/podcast/" + params.EnName);
  })
  useEffect(() => {
    MakeChangeCanonical("https://podkadeh.com/podcast/" + params.EnName);
  }, [params])
  useEffect(() => {
    if(data && data.podcastEn){
      ChangeTitlePage(
        "پادکده؛ " + data.podcastEn.title, 
        data.podcastEn.description,
        "https://podkadeh.com/podcast/" + params.EnName
      )
      let lastLink = "https://podkadeh.com/podcast/" + params.EnName
      let articles = [];
      if(data.podcastEn.episodes.length){
        for (let i = 0; i < data.podcastEn.episodes.length; i++) {
          const episode = data.podcastEn.episodes[i];
          let time = new Date(episode.pubDate)
          articles.push({
            "@id": "https://podkadeh.com/podcast/" + params.EnName + "/" + episode.Ep_ID + "/#article",
            "@type": "Article",
            "headline": episode.Title,
            "url": "https://podkadeh.com/podcast/" + params.EnName + "/" + episode.Ep_ID,
            "datePublished": time.toISOString(),
            "image": episode.image,
            "author": { 
              "@id": "https://podkadeh.com/podcast/" + params.EnName + "/#author" ,
              "url": "https://podkadeh.com/podcast/" + data.podcastEn.title, 
              "name": "https://podkadeh.com/podcast/" + params.EnName, 
            }
          });
        }
      }
      let profile = {
        "@type": "ProfilePage",
        "mainEntity": {
          "@id": "https://podkadeh.com/podcast/" + params.EnName + "/#author",
          "@type": "Organization",
          "name": data.podcastEn.title,
          "alternateName": params.EnName,
          "identifier": params.EnName,
          "description": data.podcastEn.description,
          "image": data.podcastEn.imagecache.size500,
          "url": "https://podkadeh.com/podcast/" + params.EnName + "/",
        },
        "url": "https://podkadeh.com/podcast/" + params.EnName + "/",
        "hasPart": articles
      }
      let breadData = [
        {"@type":"ListItem", "position":1, "name": "podkadeh", "item": "https://podkadeh.com/"},
        {"@type":"ListItem", "position":2, "name": data.podcastEn.title , "item": "https://podkadeh.com/podcast/" + params.EnName},
      ];
      generateRichSchema(profile, false, {
        "@type":"BreadcrumbList",
        "@id":lastLink + "/#breadcrumb",
        "itemListElement": breadData
      })
    }
  }, [data])
  const getEpisodes = () => {
    let rows = [];
    let length = data.podcastEn.episodes.length;
    if(length > loadedEp){
        length = loadedEp
    }
    for (let i = 0; i < length; i++) {
        const value = data.podcastEn.episodes[i];
        rows.push(
            <EpisodeCartList 
              key={i} 
              Pod_ID={value.Pod_ID} 
              Ep_ID={value.Ep_ID} 
              image={value.image} 
              title={value.Title} 
              podcast={value.pubDate} 
              openCart={props.openCart}
              baseLink={"/podcast/" + data.podcastEn.En_Name + "/" + value.Ep_ID}
            />
        )
    }
    return rows;
  }
  const loadMore = () => {
    if((data.podcastEn.episodes.length - loadedEp) > 10){
        setLoadedEp(loadedEp + 10);
    } else {
        setLoadedEp(data.podcastEn.episodes.length);
    }
  }
  const shareHandel = () => {
    let link = encodeURI("https://podkadeh.com/podcast/" + params.EnName)
    if(typeof window.navigator.share === "function" ){
      window.navigator.share({
        title: "Share " + data.podcastEn.title,
        text: "link: ",
        url: link,
      });
      return;
    }
    navigator.clipboard.writeText(link).then(() => {
      props.updateError({
        text: 'لینک کپی شد',
        time: 3,
        type: 'confirm'
      });
    })
  };
  if (loading) return (
    <MainLayout {...props}>
      <Style>
        <div className="loading">
            <Loading/>
        </div>
      </Style>
    </MainLayout>
  );
  if (error || (data && data.podcastEn && data.podcastEn.Pod_ID === "")) {
    console.error("kdmaskdmkasd", error);
    return <NotMatch/>;
  };
  function html_encode(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const p = data.podcastEn;
  const floatingElement = html_encode(p.description)
  return (
    <MainLayout {...props}>
      <Style>
        <div className="top-part">
          <img src={p.imagecache.size500} alt={p.title}/>
        </div>
        <div className="podcast-title">
          <div className="row">
            <div className="image">
              <img src={p.imagecache.size500} alt={p.title}/>
            </div>
            <div className="data">
              <h1 className="title">
                {p.title}
              </h1>
              <h3 className="autor">
                {p.author}
              </h3>
            </div>
            <div className="expand"></div>
            <div className="actions">
                <div className="action" onClick={shareHandel}>
                  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M27.1471 17.4733C26.6538 17.4733 26.2404 17.1 26.1871 16.6066C25.8671 13.6733 24.2938 11.0333 21.8671 9.35331C21.4271 9.04664 21.3204 8.44664 21.6271 8.00664C21.9338 7.56664 22.5338 7.45997 22.9738 7.76664C25.8671 9.77997 27.7338 12.9266 28.1204 16.4066C28.1738 16.94 27.7871 17.42 27.2538 17.4733C27.2138 17.4733 27.1871 17.4733 27.1471 17.4733Z" fill="white"/>
                    <path opacity="0.4" d="M4.9864 17.54C4.95973 17.54 4.91973 17.54 4.89307 17.54C4.35973 17.4867 3.97307 17.0067 4.0264 16.4734C4.3864 12.9934 6.2264 9.84669 9.09307 7.82002C9.51973 7.51336 10.1331 7.62002 10.4397 8.04669C10.7464 8.48669 10.6397 9.08669 10.2131 9.39336C7.81307 11.1 6.25307 13.74 5.95973 16.66C5.9064 17.1667 5.47973 17.54 4.9864 17.54Z" fill="white"/>
                    <path opacity="0.4" d="M21.3201 28.6334C19.6801 29.42 17.9201 29.82 16.0801 29.82C14.1601 29.82 12.3335 29.3934 10.6268 28.5267C10.1468 28.3 9.96013 27.7134 10.2001 27.2334C10.4268 26.7534 11.0135 26.5667 11.4935 26.7934C12.3335 27.22 13.2268 27.5134 14.1335 27.6867C15.3601 27.9267 16.6135 27.94 17.8401 27.7267C18.7468 27.5667 19.6401 27.2867 20.4668 26.8867C20.9601 26.66 21.5468 26.8467 21.7601 27.34C22.0001 27.82 21.8135 28.4067 21.3201 28.6334Z" fill="white"/>
                    <path d="M16.0666 3.18005C14 3.18005 12.3066 4.86005 12.3066 6.94005C12.3066 9.02005 13.9866 10.7001 16.0666 10.7001C18.1466 10.7001 19.8266 9.02005 19.8266 6.94005C19.8266 4.86005 18.1466 3.18005 16.0666 3.18005Z" fill="white"/>
                    <path d="M6.73363 18.9933C4.66697 18.9933 2.97363 20.6733 2.97363 22.7533C2.97363 24.8333 4.65363 26.5133 6.73363 26.5133C8.81363 26.5133 10.4936 24.8333 10.4936 22.7533C10.4936 20.6733 8.8003 18.9933 6.73363 18.9933Z" fill="white"/>
                    <path d="M25.2668 18.9933C23.2002 18.9933 21.5068 20.6733 21.5068 22.7533C21.5068 24.8333 23.1868 26.5133 25.2668 26.5133C27.3468 26.5133 29.0268 24.8333 29.0268 22.7533C29.0268 20.6733 27.3468 18.9933 25.2668 18.9933Z" fill="white"/>
                  </svg>
                  <div>
                    اشتراک گذاری
                  </div>
                </div>
              </div>
          </div>
          <div className="line"></div>
          <div className="row">
            <div className="data">
              <div className="desc-title">
                توضیحات:
              </div>
              <div className="desc">
                {parse(floatingElement)}
              </div>
            </div>
            <div className="actions">

            </div>
          </div>
        </div>

        <div className="episodes">
          <h2 className="title-list">
            لیست اپیزود ها
          </h2>
              {getEpisodes()}
              <EndObserver func={loadMore}/>
        </div>
      </Style>
    </MainLayout>
  );
}
