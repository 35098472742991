import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "./Layout/MainLayout";
import TopHome from "../Components/Home/TopHome";
import ShowMore from "../Components/Home/ShowMore";
import Section2 from "../Components/Home/Section2";
import Section3 from "../Components/Home/Section3";
import Section4 from "../Components/Home/Section4";
import Section5 from "../Components/Home/Section5";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import { Loading } from "../Components/Loading";
import EndObserver from "../Components/mainComp/EndObserver";
import closeSearch from "../Assets/svg/close-search.svg"
import ExploreSVG from "../Assets/svg/ExploreSVG";
import SearchSVG from "../Assets/svg/SearchSVG";
import CloseSearch from "../Assets/svg/CloseSearch";
import parse from 'html-react-parser';

const Style = styled.div`
  /* min-height: 100vh; */
  display: grid;
  grid-template-columns:
    6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
    6.09%;
  grid-column-gap: 1.56%;
  .top-part {
    min-height: 300px;
    grid-column: 1/15;
    background-color: #F9CEB3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    .center {
      width: 50%;
      @media screen and (max-width: 850px) {
        width: 70%;
        height: 100%;
      }
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: auto;
        display: block;
        @media screen and (max-width: 850px) {
          height: 90%;
        }
      }
    }
    .image-rand {
      position: absolute;
      width: 100px;
      height: 100px;
      animation: float-3 7s ease-in-out infinite;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 1000px;
        border: 3px solid #000000;
        box-shadow: 6px 6px 0px 0px #000000;
      }
      &.pos-0 {
        width: 110px;
        height: 110px;
        top: 40px;
        right: 70px;
        animation-delay: -1s;
      }
      &.pos-1 {
        width: 90px;
        height: 90px;
        top: calc(50% - 35px);
        right: 15%;
        animation-delay: -1s;
      }
      &.pos-2 {
        width: 150px;
        height: 150px;
        bottom: -10px;
        right: -30px;
        animation-delay: -2s;
      }
      &.pos-3 {
        width: 110px;
        height: 110px;
        top: 60px;
        left: 70px;
        animation-delay: -6s;
      }
      &.pos-4 {
        width: 90px;
        height: 90px;
        top: calc(50% - 35px);
        left: 15%;
        animation-delay: -3s;
      }
      &.pos-5 {
        width: 150px;
        height: 150px;
        bottom: -50px;
        left: -10px;
        animation-delay: -4s;
      }
      @media screen and (max-width: 850px) {
        &.pos-0 {
          width: 80px;
          height: 80px;
          top: 40px;
          right: 5px;
        }
        &.pos-1 {
          width: 60px;
          height: 60px;
          top: calc(50% - 35px);
          right: 15%;
        }
        &.pos-2 {
          width: 100px;
          height: 100px;
          bottom: 20px;
          right: -30px;
        }
        &.pos-3 {
          width: 80px;
          height: 80px;
          top: 40px;
          left: 10px;
        }
        &.pos-4 {
          width: 60px;
          height: 60px;
          top: calc(50% - 35px);
          left: 15%;
        }
        &.pos-5 {
          width: 100px;
          height: 100px;
          bottom: 20px;
          left: -10px;
        }
      }
    }
  }
  @keyframes float-3 {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-30px);
        }
        100% {
            transform: translatey(0px);
        }
    }
  .search-box{
    margin: -1.4rem 0 1rem;
    grid-column: 2/14;
    display: flex;
    justify-content: center;
    .search-input {
      min-width: 50%;
      @media screen and (max-width: 850px) {
        min-width: 100%;
      }
      border: 3px solid #000000;
      border-radius: 4px;
      position: relative;
      input {
        padding: 0.5rem 40px;
        outline: none;
        border: none;
        min-width: calc(100% - 80px);
      }
      .icon-base {
        width: 32px;
        height: calc(100%);
        padding: 0 4px;
        position: absolute;
        inset: 0 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
      .icon {
        width: 32px;
        height: 32px;
        position: absolute;
        inset: 4px auto 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #000;
        border-radius: 100px;
        svg {
          width: 24px;
          height: 24px;
          display: block;
          fill: #fff;
          transform: scale(1.25);
        }
      }
    }
    
  }
  .podcast-list {
    margin: 1rem 0;
    grid-column: 2/14;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .podcast {
    display: flex;
    gap: 1rem;
    padding: 1rem 1.5rem;
    border: 3px solid #000000;
    box-shadow: 6px 6px 0px 0px #000000;
    border-radius: 4px;
    text-decoration: none;
    color: inherit;
    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: center;
      .data {
        align-items: center;
        
      }
      .line {
        display: none;
      }
    }
    img {
      border: 1px solid #000000;
      width: 130px;
      aspect-ratio: 1;
      display: block;
      border-radius: 2px;
    }
    .data {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      .title {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
    .line {
      width: 1px;
      background-color: #000000;
      margin: -1rem 0;
    }
    .desc {
      width: 27%;
      height: 130px;
      white-space: pre-wrap;
      flex-grow: 1;
      overflow: hidden;
      @media screen and (max-width: 850px) {
        width: 100%;
        height: auto;
        max-height: 130px;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .action {
        text-decoration: none;
        background: #EA5C00;
        border: 3px solid #000000;
        color: #fff;
        padding: 0.5rem 1rem;
        font-weight: 500;
      }
    }
  }
  
`;

const PodcastsList_query = gql`
  query PodcastsList($page: Int, $paginate: Int) {
    listPodcasts(page: $page, paginate: $paginate) {
      pages
      page
      paginate
      podcasts {
        Pod_ID
        title
        Cat_Name
        Pr_Name
        En_Name
        description
        imagecache {
          size500
        }
      }
    }
  }
`;
const PodcastsListSearch_query = gql`
  query PodcastsListSearch($key: String,$page: Int) {
    search(Key: $key, page: $page, Podcast: true) {
      pages
      page
      paginate
      podcasts {
        Pod_ID
        title
        Pr_Name
        En_Name
        description
        imagecache {
          size500
        }
      }
    }
  }
`;
export default function Podcasts(props) {
  const params = useSearchParams();
  // const [sumData, setSumData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("all");
  const [randomSelected, setRandomSelected] = useState([]);
  const paginate = 10;
  // const [pages, setPages] = useState(1);
  const [refetchS, {data: dataS, loading: loadingS, error: errorS, fetchMore: fetchMoreS}] = useLazyQuery(PodcastsListSearch_query, {
    notifyOnNetworkStatusChange: true,
  });
  const {data, loading, error, fetchMore} = useQuery(PodcastsList_query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 0,
      paginate: paginate
    }
  });
  useEffect(() => {
    let random = [];
    let length = imageList.length
    for (let i = 0; i < 6; i++) {
      let randomNum = parseInt(Math.random() * length);
      let find = random.findIndex((v) => v === randomNum);
      if(find === -1){
        random.push(randomNum);
      } else {
        i = i - 1;
      }
    }
    setRandomSelected(random)
  }, [])
  var sumData;
  var page;
  var pages;
  if(type === "all"){
    sumData = (data && data.listPodcasts) ? data.listPodcasts.podcasts : [];
    page = (data && data.listPodcasts) ? data.listPodcasts.page : 0;
    pages = (data && data.listPodcasts) ? data.listPodcasts.pages : 0;
  }
  if(type === "search"){
    sumData = (dataS && dataS.search) ? dataS.search.podcasts : [];
    page = (dataS && dataS.search) ? dataS.search.page : 0;
    pages = (dataS && dataS.search) ? dataS.search.pages : 0;
  }
  const loadMore = () => {
    if(loading || loadingS){
      return;
    }
    if(error){
      return;
    }
    if(type === "all"){
      if((data.listPodcasts.page) === page && page + 1 < pages){
        fetchMore({
          variables: {
            page: page + 1,
            paginate: paginate
          }
        })
      }
    }
    if(type === "search"){
      if((dataS.search.page) === page && page + 1 < pages){
        fetchMoreS({
          variables: {
            key: searchKey,
            page: page + 1,
          }
        })
      }
    }
  }
  function html_encode(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const makePodcasts = () => {
    if(sumData){
      return sumData.map((v, i) => {
        const floatingElement = html_encode(v.description)
        return (
          <Link  to={"/podcast/"+v.En_Name} className="podcast">
            <img src={v.imagecache.size500} alt={v.title}/>
            <div className="data">
              <div className="title">
                {v.title}
              </div>
              <div className="category">
                {v.Cat_Name}
              </div>
            </div>
            <div className="line"></div>
            <div className="desc">
              {parse(floatingElement)}
            </div>
            <div className="line"></div>
            <div className="actions">
              <Link to={"/podcast/"+v.En_Name} className="action">
                مشاهده پادکست
              </Link>
            </div>
          </Link>
        )
      })
    }
    return ""
  }
  const makeRandomTop = () => {
    let items = randomSelected.map((v, i) => {
      return (
        <div className={"image-rand" + " pos-"+i} key={i}>
          <img src={imageList[v]} alt="random podcasts"/>
        </div>
      )
    })
    return items
  }
  return (
    <MainLayout {...props}>
      <Style>
        <div className="top-part">
          <div className="center">
            <svg width="709" height="144" viewBox="0 0 709 144" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_3_423)">
              <rect x="3" y="61.6284" width="8.88868" height="16.5818" rx="4.44434" fill="#ADD0D8"/>
              <rect x="1.5" y="60.1284" width="11.8887" height="19.5818" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="1.5" y="60.1284" width="11.8887" height="19.5818" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter1_d_3_423)">
              <rect x="23.8887" y="52.1531" width="8.88868" height="35.5323" rx="4.44434" fill="#ADD0D8"/>
              <rect x="22.3887" y="50.6531" width="11.8887" height="38.5323" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="22.3887" y="50.6531" width="11.8887" height="38.5323" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter2_d_3_423)">
              <rect x="44.7773" y="42.6777" width="8.88868" height="54.4829" rx="4.44434" fill="#ADD0D8"/>
              <rect x="43.2773" y="41.1777" width="11.8887" height="57.4829" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="43.2773" y="41.1777" width="11.8887" height="57.4829" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter3_d_3_423)">
              <rect x="65.666" y="45.6389" width="8.88868" height="48.5609" rx="4.44434" fill="#ADD0D8"/>
              <rect x="64.166" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="64.166" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter4_d_3_423)">
              <rect x="86.5547" y="36.1636" width="8.88868" height="67.5114" rx="4.44434" fill="#ADD0D8"/>
              <rect x="85.0547" y="34.6636" width="11.8887" height="70.5114" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="85.0547" y="34.6636" width="11.8887" height="70.5114" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter5_d_3_423)">
              <rect x="107.443" y="20.1741" width="8.88868" height="99.4905" rx="4.44434" fill="#ADD0D8"/>
              <rect x="105.943" y="18.6741" width="11.8887" height="102.491" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="105.943" y="18.6741" width="11.8887" height="102.491" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter6_d_3_423)">
              <rect x="128.332" y="36.7556" width="8.88868" height="66.327" rx="4.44434" fill="#ADD0D8"/>
              <rect x="126.832" y="35.2556" width="11.8887" height="69.327" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="126.832" y="35.2556" width="11.8887" height="69.327" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter7_d_3_423)">
              <rect x="149.221" y="53.9297" width="8.88868" height="31.9791" rx="4.44434" fill="#ADD0D8"/>
              <rect x="147.721" y="52.4297" width="11.8887" height="34.9791" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="147.721" y="52.4297" width="11.8887" height="34.9791" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter8_d_3_423)">
              <rect x="170.109" y="50.9688" width="8.88868" height="37.9012" rx="4.44434" fill="#ADD0D8"/>
              <rect x="168.609" y="49.4688" width="11.8887" height="40.9012" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="168.609" y="49.4688" width="11.8887" height="40.9012" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter9_d_3_423)">
              <rect x="190.998" y="45.6389" width="8.88868" height="48.5609" rx="4.44434" fill="#ADD0D8"/>
              <rect x="189.498" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="189.498" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter10_d_3_423)">
              <rect x="211.887" y="56.8906" width="8.88868" height="26.057" rx="4.44434" fill="#ADD0D8"/>
              <rect x="210.387" y="55.3906" width="11.8887" height="29.057" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="210.387" y="55.3906" width="11.8887" height="29.057" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter11_d_3_423)">
              <rect x="232.775" y="3" width="8.88868" height="133.838" rx="4.44434" fill="#ADD0D8"/>
              <rect x="231.275" y="1.5" width="11.8887" height="136.838" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="231.275" y="1.5" width="11.8887" height="136.838" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter12_d_3_423)">
              <rect x="253.664" y="22.5427" width="8.88868" height="94.7529" rx="4.44434" fill="#ADD0D8"/>
              <rect x="252.164" y="21.0427" width="11.8887" height="97.7529" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="252.164" y="21.0427" width="11.8887" height="97.7529" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter13_d_3_423)">
              <rect x="274.553" y="29.0571" width="8.88868" height="81.7244" rx="4.44434" fill="#ADD0D8"/>
              <rect x="273.053" y="27.5571" width="11.8887" height="84.7244" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="273.053" y="27.5571" width="11.8887" height="84.7244" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter14_d_3_423)">
              <rect x="295.441" y="18.9895" width="8.88868" height="101.859" rx="4.44434" fill="#ADD0D8"/>
              <rect x="293.941" y="17.4895" width="11.8887" height="104.859" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="293.941" y="17.4895" width="11.8887" height="104.859" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter15_d_3_423)">
              <rect x="316.33" y="25.5039" width="8.88868" height="88.8308" rx="4.44434" fill="#ADD0D8"/>
              <rect x="314.83" y="24.0039" width="11.8887" height="91.8308" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="314.83" y="24.0039" width="11.8887" height="91.8308" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter16_d_3_423)">
              <rect x="337.219" y="18.9895" width="8.88868" height="101.859" rx="4.44434" fill="#ADD0D8"/>
              <rect x="335.719" y="17.4895" width="11.8887" height="104.859" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="335.719" y="17.4895" width="11.8887" height="104.859" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter17_d_3_423)">
              <rect x="358.107" y="29.6492" width="8.88868" height="80.54" rx="4.44434" fill="#ADD0D8"/>
              <rect x="356.607" y="28.1492" width="11.8887" height="83.54" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="356.607" y="28.1492" width="11.8887" height="83.54" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter18_d_3_423)">
              <rect x="378.996" y="46.8232" width="8.88868" height="46.192" rx="4.44434" fill="#ADD0D8"/>
              <rect x="377.496" y="45.3232" width="11.8887" height="49.192" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="377.496" y="45.3232" width="11.8887" height="49.192" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter19_d_3_423)">
              <rect x="399.885" y="29.0571" width="8.88868" height="81.7244" rx="4.44434" fill="#ADD0D8"/>
              <rect x="398.385" y="27.5571" width="11.8887" height="84.7244" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="398.385" y="27.5571" width="11.8887" height="84.7244" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter20_d_3_423)">
              <rect x="420.773" y="43.27" width="8.88868" height="53.2985" rx="4.44434" fill="#ADD0D8"/>
              <rect x="419.273" y="41.77" width="11.8887" height="56.2985" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="419.273" y="41.77" width="11.8887" height="56.2985" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter21_d_3_423)">
              <rect x="441.662" y="50.9688" width="8.88868" height="37.9012" rx="4.44434" fill="#ADD0D8"/>
              <rect x="440.162" y="49.4688" width="11.8887" height="40.9012" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="440.162" y="49.4688" width="11.8887" height="40.9012" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter22_d_3_423)">
              <rect x="462.551" y="62.2205" width="8.88868" height="15.3973" rx="4.44434" fill="#ADD0D8"/>
              <rect x="461.051" y="60.7205" width="11.8887" height="18.3973" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="461.051" y="60.7205" width="11.8887" height="18.3973" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter23_d_3_423)">
              <rect x="483.439" y="53.3374" width="8.88868" height="33.1635" rx="4.44434" fill="#ADD0D8"/>
              <rect x="481.939" y="51.8374" width="11.8887" height="36.1635" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="481.939" y="51.8374" width="11.8887" height="36.1635" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter24_d_3_423)">
              <rect x="504.328" y="22.5427" width="8.88868" height="94.7529" rx="4.44434" fill="#ADD0D8"/>
              <rect x="502.828" y="21.0427" width="11.8887" height="97.7529" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="502.828" y="21.0427" width="11.8887" height="97.7529" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter25_d_3_423)">
              <rect x="525.217" y="45.6389" width="8.88868" height="48.5609" rx="4.44434" fill="#ADD0D8"/>
              <rect x="523.717" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="523.717" y="44.1389" width="11.8887" height="51.5609" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter26_d_3_423)">
              <rect x="546.105" y="30.8337" width="8.88868" height="78.1711" rx="4.44434" fill="#ADD0D8"/>
              <rect x="544.605" y="29.3337" width="11.8887" height="81.1711" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="544.605" y="29.3337" width="11.8887" height="81.1711" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter27_d_3_423)">
              <rect x="566.994" y="30.8337" width="8.88868" height="78.1711" rx="4.44434" fill="#ADD0D8"/>
              <rect x="565.494" y="29.3337" width="11.8887" height="81.1711" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="565.494" y="29.3337" width="11.8887" height="81.1711" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter28_d_3_423)">
              <rect x="587.883" y="19.5818" width="8.88868" height="100.675" rx="4.44434" fill="#ADD0D8"/>
              <rect x="586.383" y="18.0818" width="11.8887" height="103.675" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="586.383" y="18.0818" width="11.8887" height="103.675" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter29_d_3_423)">
              <rect x="608.771" y="37.3479" width="8.88868" height="65.1426" rx="4.44434" fill="#ADD0D8"/>
              <rect x="607.271" y="35.8479" width="11.8887" height="68.1426" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="607.271" y="35.8479" width="11.8887" height="68.1426" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter30_d_3_423)">
              <rect x="629.66" y="40.9011" width="8.88868" height="58.0361" rx="4.44434" fill="#ADD0D8"/>
              <rect x="628.16" y="39.4011" width="11.8887" height="61.0361" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="628.16" y="39.4011" width="11.8887" height="61.0361" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter31_d_3_423)">
              <rect x="650.549" y="56.2986" width="8.88868" height="27.2415" rx="4.44434" fill="#ADD0D8"/>
              <rect x="649.049" y="54.7986" width="11.8887" height="30.2415" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="649.049" y="54.7986" width="11.8887" height="30.2415" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter32_d_3_423)">
              <rect x="671.438" y="47.4155" width="8.88868" height="45.0076" rx="4.44434" fill="#ADD0D8"/>
              <rect x="669.938" y="45.9155" width="11.8887" height="48.0076" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="669.938" y="45.9155" width="11.8887" height="48.0076" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <g filter="url(#filter33_d_3_423)">
              <rect x="692.326" y="61.6284" width="8.88868" height="16.5818" rx="4.44434" fill="#ADD0D8"/>
              <rect x="690.826" y="60.1284" width="11.8887" height="19.5818" rx="5.94434" stroke="black" strokeWidth="3"/>
              <rect x="690.826" y="60.1284" width="11.8887" height="19.5818" rx="5.94434" stroke="black" strokeOpacity="0.2" strokeWidth="3"/>
              </g>
              <defs>
              <filter id="filter0_d_3_423" x="0" y="58.6284" width="18.8887" height="26.5818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter1_d_3_423" x="20.8887" y="49.1531" width="18.8887" height="45.5322" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter2_d_3_423" x="41.7773" y="39.6777" width="18.8887" height="64.4829" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter3_d_3_423" x="62.666" y="42.6389" width="18.8887" height="58.5608" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter4_d_3_423" x="83.5547" y="33.1636" width="18.8887" height="77.5115" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter5_d_3_423" x="104.443" y="17.1741" width="18.8887" height="109.49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter6_d_3_423" x="125.332" y="33.7556" width="18.8887" height="76.3269" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter7_d_3_423" x="146.221" y="50.9297" width="18.8887" height="41.979" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter8_d_3_423" x="167.109" y="47.9688" width="18.8887" height="47.9011" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter9_d_3_423" x="187.998" y="42.6389" width="18.8887" height="58.5608" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter10_d_3_423" x="208.887" y="53.8906" width="18.8887" height="36.0571" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter11_d_3_423" x="229.775" y="0" width="18.8887" height="143.838" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter12_d_3_423" x="250.664" y="19.5427" width="18.8887" height="104.753" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter13_d_3_423" x="271.553" y="26.0571" width="18.8887" height="91.7244" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter14_d_3_423" x="292.441" y="15.9895" width="18.8887" height="111.859" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter15_d_3_423" x="313.33" y="22.5039" width="18.8887" height="98.8308" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter16_d_3_423" x="334.219" y="15.9895" width="18.8887" height="111.859" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter17_d_3_423" x="355.107" y="26.6492" width="18.8887" height="90.54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter18_d_3_423" x="375.996" y="43.8232" width="18.8887" height="56.1921" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter19_d_3_423" x="396.885" y="26.0571" width="18.8887" height="91.7244" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter20_d_3_423" x="417.773" y="40.27" width="18.8887" height="63.2986" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter21_d_3_423" x="438.662" y="47.9688" width="18.8887" height="47.9011" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter22_d_3_423" x="459.551" y="59.2205" width="18.8887" height="25.3975" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter23_d_3_423" x="480.439" y="50.3374" width="18.8887" height="43.1636" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter24_d_3_423" x="501.328" y="19.5427" width="18.8887" height="104.753" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter25_d_3_423" x="522.217" y="42.6389" width="18.8887" height="58.5608" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter26_d_3_423" x="543.105" y="27.8337" width="18.8887" height="88.1711" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter27_d_3_423" x="563.994" y="27.8337" width="18.8887" height="88.1711" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter28_d_3_423" x="584.883" y="16.5818" width="18.8887" height="110.675" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter29_d_3_423" x="605.771" y="34.3479" width="18.8887" height="75.1426" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter30_d_3_423" x="626.66" y="37.9011" width="18.8887" height="68.0361" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter31_d_3_423" x="647.549" y="53.2986" width="18.8887" height="37.2415" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter32_d_3_423" x="668.438" y="44.4155" width="18.8887" height="55.0076" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              <filter id="filter33_d_3_423" x="689.326" y="58.6284" width="18.8887" height="26.5818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="4" dy="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_423"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_423" result="shape"/>
              </filter>
              </defs>
            </svg>
            {makeRandomTop()}
          </div>
        </div>
        <div className="search-box">
          <div className="search-input">
            <div className="icon-base">
              <SearchSVG/>
            </div>
            <input 
              type="text"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
                if(e.target.value.length === 0){
                  setType("all");
                  return;
                }
                if(e.target.value.length > 0){
                  setType("search");
                  refetchS({
                    variables: {
                      key: e.target.value,
                      page: 0
                    }
                  })
                }
              }}
            />
            {
              searchKey.length > 0 ? (
                <div className="icon" onClick={() => {
                  setType("all");
                  setSearchKey("");
                }}>
                  <CloseSearch/>
                </div>
              ) : ""
            }
          </div>
          
        </div>
        <div className="podcast-list">
          {makePodcasts()}
          {
            (loading || loadingS) ? (
              <Loading/>
            ) : (
              <EndObserver func={loadMore}/>
            )
          }
        </div>
      </Style>
    </MainLayout>
  );
}



const imageList = [
  "https://api.podkadeh.com/image-cache/Pod-6261b6972c7271ff0c99e9b1-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-62ded29623879b1040d85656-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-62eb90af78b5b38084b7c98d-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-62eb917878b5b38084b7c99c-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-62eb921e78b5b38084b7c9a6-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-62eb929f78b5b38084b7c9b4-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-630258c758a0c38881812b52-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b01c88532224eae69fa4-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b05f88532224eae69fa9-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b08188532224eae69fae-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b10a88532224eae69fb3-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b2e488532224eae69ff5-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-6331b3eb88532224eae69fff-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-66a0d954914faa5cf3301b40-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-66a0daf8914faa5cf3301e29-500.jpg",
  "https://api.podkadeh.com/image-cache/Pod-66a4d063914faa5cf337af09-500.jpg",
]