import Footer from "../../Components/Menu/Footer";
import Navbar from "../../Components/Menu/Navbar";
import { useState } from "react";
import React from "react";
import BergerMenu from "../../Components/Menu/BergerMenu";

function MainLayout(props) {
  const [headerState, setHeaderState] = useState(false);
  return (
    <React.Fragment>
      <Navbar sideState={headerState} funcSide={setHeaderState} {...props} />
      <BergerMenu sideState={headerState} funcSide={setHeaderState} />
      {props.children}
      <Footer {...props} />
    </React.Fragment>
  );
}

export default MainLayout;
