import React, { useRef, useState } from "react";
import styled from "styled-components";
import midtick from "../../Assets/new1/ticket-mid.svg"
import migrate from "../../Assets/new1/migrate-pic.png"
import { TextInputWTitle } from "../Helper/TextInputWTitle";
import { TextBtn } from "../Inputs/TextBtn";
import { gql, useMutation } from "@apollo/client";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
const Style = styled.div`
    margin-top: 7rem;
    margin-bottom: 7rem;
    grid-column: 2/14;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    position: relative;
    .circle-back1 {
        position: absolute;
        width: 350px;
        height: 350px;
        border-radius: 200px;
        background-color: #C7DFBE;
        top: calc(50% - 175px);
        left: calc(22% - 175px);
        box-shadow: var(--n-shadow-1);
        border: 3px solid var(--n-text-6);
        animation: float-3 7s ease-in-out infinite;
        animation-delay: -3s;
    }
    .sections {
        flex-grow: 1;
        display: flex;
        position: relative;
    }
    --shadow-border-r: 2rem;
    &.home5-ltr {
        .shadows {
            .shadow1 {
                border-radius: 4px var(--shadow-border-r) var(--shadow-border-r) 4px;
            }
            .shadow2 {
                border-radius: var(--shadow-border-r) 4px 4px var(--shadow-border-r);
            }
        }
        .section1 {
            border-radius: 4px var(--shadow-border-r) var(--shadow-border-r) 4px;
            .subtitle-main {
                text-align: left;
            }
        }
        .section2 {
            border-radius: var(--shadow-border-r) 4px 4px var(--shadow-border-r);
        }
    }
    .shadows {
        z-index: -1;
        position: absolute;
        inset: 0 0 0 0;
        display: flex;
        .shadow1 {
            flex-grow: 1;
            width: calc(35% - 2rem - 3px);
            border-radius: var(--shadow-border-r) 4px 4px var(--shadow-border-r);
            box-shadow: var(--n-shadow-1);
            padding: 5rem 1rem;
        }
        .shadow2 {
            flex-grow: 1;
            width: calc(65% - 10rem - 5px);
            border-radius: 4px var(--shadow-border-r) var(--shadow-border-r) 4px;
            box-shadow: var(--n-shadow-1);
            padding: 4rem 6rem 4rem 4rem;
        }
        .shadow3 {
            flex-grow: 1;
            width: calc(100% - 8rem - 5px);
            border-radius: 4px;
            box-shadow: var(--n-shadow-1);
            padding: 4rem 4rem 4rem 4rem;
        }
        .dashed-shadow {
            width: 2px;
            margin: 4.2% 0;
        }
    }
    .section1 {
        z-index: 1;
        width: calc(35% - 2rem - 3px);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        text-align: center;
        padding: 5rem 1rem;
        background-color: #CDC2DA;
        border-radius: var(--shadow-border-r) 4px 4px var(--shadow-border-r);
        /* box-shadow: var(--n-shadow-1); */
        border: 3px solid var(--n-text-6);
        /* border-left: 1px solid #000000; */
        .image-migrate {
            width: 75%;
            margin: 0rem 0;
            img {
                width: 100%;
                display: block;
            }
        }
        .title-main {
            font-size: 40px;
            font-weight: 900;
            color: #7652A3;
        }
        .subtitle-main {
            margin: 0 3rem;
            text-align: right;
            font-size: 16px;
            font-weight: 500;
            color: #000;
        }
    }
    .section2 {
        z-index: 1;
        /* border-right: 1px solid #000000; */
        padding: 4rem 6rem 4rem 4rem;
        width: calc(65% - 10rem - 3px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--n-body-3);
        border-radius: 4px var(--shadow-border-r) var(--shadow-border-r) 4px;
        /* box-shadow: var(--n-shadow-1); */
        border: 3px solid var(--n-text-6);
        .title-main {
            font-size: 24px;
            font-weight: 900;
            margin-bottom: 1rem;
        }
        .btn {
            margin-right: auto;
            width: 60%;
        }
    }
    .section3 {
        z-index: 1;
        /* border-right: 1px solid #000000; */
        padding: 4rem 4rem 4rem 4rem;
        width: calc(100% - 8rem - 3px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--n-body-3);
        border-radius: 4px;
        /* box-shadow: var(--n-shadow-1); */
        background-color: #CDC2DA;
        border: 3px solid var(--n-text-6);
        .image-migrate {
            width: 45%;
            margin: 0rem auto 1rem;
            img {
                width: 100%;
                display: block;
            }
        }
        .title-main {
            text-align: center;
            font-size: 40px;
            font-weight: 900;
            color: #7652A3;
            margin: 0rem auto 1rem;
        }
        .subtitle-main {
            margin: 0 3rem;
            text-align: right;
            font-size: 16px;
            font-weight: 500;
            color: #000;
            text-align: center;
        }
    }
    .dashed {
        z-index: 1;
        width: 2px;
        margin: calc(var(--shadow-border-r) - 0.3rem) 0;
        /* border-left: 1px solid #000; */
        /* border-right: 1px solid #000; */
        background: repeating-linear-gradient(
            to bottom,
            white,
            white 10px,
            black 10px,
            black 20px
        );
    }
    @media screen and (max-width: 1000px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
        grid-column: 1/15;
        padding: 1rem 1rem;
        overflow: hidden;
        .sections {
            flex-direction: column;
        }
        &.home5-ltr {
            .shadows {
                .shadow1 {
                    border-radius: 4px 4px var(--shadow-border-r) var(--shadow-border-r) ;
                }
                .shadow2 {
                    border-radius: var(--shadow-border-r) var(--shadow-border-r) 4px 4px ;
                }
            }
            .section1 {
                border-radius: 4px 4px var(--shadow-border-r) var(--shadow-border-r);
                .subtitle-main {
                    text-align: left;
                }
            }
            .section2 {
                border-radius: var(--shadow-border-r) var(--shadow-border-r) 4px 4px ;
            }
        }
        .section1 {
            z-index: 2;
            width: calc(100% - 2rem - 3px);
            padding: 1rem 1rem 2rem;
            gap: 1rem;
            border-radius: 4px 4px 2rem 2rem;
            box-shadow: var(--n-shadow-1);
            .subtitle-main {
                margin: 0 1rem;
            }
        }
        .section2 {
            z-index: 3;
            padding: 2rem 1rem;
            width: calc(100% - 2rem - 6px);
            border-radius: 2rem 2rem 4px 4px;
            box-shadow: var(--n-shadow-1);
            text-align: center;
            .btn {
                margin-right: auto;
                width: 100%;
            }
        }
        .section3 {
            padding: 2rem 1rem;
            width: calc(100% - 2rem - 6px);
            .image-migrate {
                width: 75%;
            }
            .subtitle-main {
                margin: 0 1rem;
            }
        }
        .dashed {
            z-index: 4;
            width: calc(100% - 5.4rem);
            height: 2px;
            margin: 0 2.7rem;
            background: repeating-linear-gradient(
                to right,
                white,
                white 10px,
                black 10px,
                black 20px
            );
        }
        .shadows {
            display: none;
        }
    }

`
const Migrate_Mutation = gql`
  mutation Migrate(
    $Email: String,
    $Mobile: String,
    $podcastName: String,
    $Feed: String,
  ) {
    newShowMigrate(
      Email: $Email,
      Mobile: $Mobile,
      podcastName: $podcastName,
      Feed: $Feed,
    ) {
      status
      data
    }
  }
`;




export default function Section5(props) {
    const [podname, setPodName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [podfeed, setPodFeed] = useState("");

    const [sended, setSended] = useState(false);
    

    const [NewShow, addEmailState] = useMutation(Migrate_Mutation,{
        onCompleted: updateDataNewShow,
        fetchPolicy: 'network-only'
    });

    function updateDataNewShow (data) {
        if(data.newShowMigrate.status){
            props.updateError({
                text: "درخواست با موفقیت ثبت شد",
                time: 3,
                type: 'confirm'
            });
            setEmail("");
            setMobile("");
            setPodName("");
            setPodFeed("");
            setSended(true)
        } else {
          // error
          props.updateError({
              text: data.newShowMigrate.data,
              time: 3,
              type: 'error'
          });
        }
    }
    function sendData() {
        // send updated podcast data
        NewShow({
          variables: {
            Email: email,
            Mobile: mobile,
            podcastName: podname,
            Feed: podfeed,
          },
        });
    }
    onchange = (e) => {
        let value = e.target.value;
        switch (e.target.name) {
          case "podname":
            setPodName(value);
            break;
          case "mobile":
            setMobile(value);
            break;
          case "email":
            setEmail(value);
            break;
          case "podfeed":
            setPodFeed(value);
            break;
          default:
            break;
        }
    };
    const checkData = () => {
        if (
          email && 
          mobile &&
          podfeed &&
          podname
        ) {
          return true;
        } else {
          return false;
        }
    };
    const ref1 = useRef();
    if(sended){
        return (
        <Style ref={ref1} id="migrate" className={"home5-"+Language[lang].dir}>
            <div className="sections">
                <div className="shadows">
                    <div className="shadow3"></div>
                </div>
                <div className="section3">
                    <div className="image-migrate">
                        <img src={migrate} alt="migrate"/>
                    </div>
                    <div className="title-main">{Language[lang].sec5successtitle}</div>
                    <div className="subtitle-main">{Language[lang].sec5successdesc}</div>
                </div>
            </div>
        </Style>
        )
    }
    return (
        <Style ref={ref1} id="migrate" className={"home5-"+Language[lang].dir}>
            <div className="sections">
                <div className="shadows">
                <div className="shadow1"></div>
                <div className="dashed-shadow"></div>
                <div className="shadow2"></div>
                </div>
                <div className="section1">
                    <div className="image-migrate">
                        <img src={migrate} alt="migrate"/>
                    </div>
                    <div className="title-main">{Language[lang].sec5title1}</div>
                    <div className="subtitle-main">{Language[lang].sec5desc1}</div>
                </div>
                <div className="dashed"></div>
                <div className="section2">
                    <div className="title-main">{Language[lang].sec5title2}</div>
                    <div className="form">
                    <TextInputWTitle
                        ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
                        style={{ marginBottom: "0.5rem" }}
                        name={"podname"}
                        value={podname}
                        onchange={onchange}
                        error={""}
                        title={Language[lang].sec5inputpodname}
                    />
                    <TextInputWTitle
                        ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
                        style={{ marginBottom: "0.5rem" }}
                        name={"email"}
                        value={email}
                        onchange={onchange}
                        ltr={true}
                        error={""}
                        title={Language[lang].sec5inputemail}
                    />
                    <TextInputWTitle
                        ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
                        style={{ marginBottom: "0.5rem" }}
                        name={"mobile"}
                        value={mobile}
                        onchange={onchange}
                        ltr={true}
                        error={""}
                        title={Language[lang].sec5inputphone}
                    />
                    <TextInputWTitle
                        ///in margin baraye ine ke dar size kamtar >matn error be btn nechasbe
                        style={{ marginBottom: "0.5rem" }}
                        name={"podfeed"}
                        value={podfeed}
                        onchange={onchange}
                        error={""}
                        ltr={true}
                        title={Language[lang].sec5inputfeed}
                    />
                    </div>
                    <div className="btn">
                    <TextBtn
                        type={"button"}
                        text={Language[lang].sec5inputcta}
                        onclick={sendData}
                        loading={addEmailState.loading}
                        disabled={!checkData() || addEmailState.loading}
                    />
                    </div>
                </div>
            </div>
        </Style>
    )
}
