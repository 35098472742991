import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconInstagram } from "../../Assets/Icons/IconInstagram";
import { IconLinkdin } from "../../Assets/Icons/IconLinkdin";
import { IconTwitter } from "../../Assets/Icons/IconTwitter";
import logopodkadehfooter from "../../Assets/logopodkadehfooter.png";
import { gql, useMutation } from "@apollo/client";
import { TextInputWTitle } from "../Helper/TextInputWTitle";
import { TextBtn } from "../Inputs/TextBtn";
import { Language } from "../../Language/Language";
import { lang } from "../../App";
import logoen from "../../Assets/Logo-En.svg"

// import { DownloadAppBtn } from "../Download App/DownloadAppBtn";
// import { IconApple } from "../../Assets/Icons/IconApple";
// import { IconBazar } from "../../Assets/Icons/IconBazar";
// import { IconSibApp } from "../../Assets/Icons/IconSibApp";
// import { IconSibche } from "../../Assets/Icons/IconSibche";
// import { IconMyket } from "../../Assets/Icons/IconMyket";
// import { IconAndroid } from "../../Assets/Icons/IconAndroid";
// import { IconAnardoni } from "../../Assets/Icons/IconAnardoni";
const Foter = styled.footer`
  width: 100%;
  min-height: 400px;
  background: var(--primary-1);
  box-sizing: border-box;
  --fs-title: 22px;
  --fs-text: 16px;
  color: var(--text-1);
  &.footer-ltr {
    direction: ltr;
    text-align: start;
  }
  .top-part {
    display: grid;
    grid-template-columns:
      6.09% repeat(12, calc(calc(84.7% / 12) - calc(17.16% / 12)))
      6.09%;
    grid-column-gap: 1.56%;
  }
  .bottom-part {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    color: var(--text-1);
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .column-1 {
    grid-column: 2/6;
  }
  .column-2 {
    grid-column: 6/10;
  }
  .column-3 {
    grid-column: 10/14;
  }
  .title {
    font-weight: 400;
    font-size: var(--fs-title);
    display: flex;
    align-items: center;
    text-align: start;
    color: var(--text-1);
  }
  
  .pod-desc {
    margin-top: 64px;
    img {
      max-height: 87px;
      max-width: 229px;
      margin-bottom: 16px;
    }
    .desc {
      font-weight: 400;
      font-size: var(--fs-text);
      display: flex;
      align-items: center;
      text-align: start;
      color: var(--text-6);
    }
  }

  .items-link {
    flex-grow: 1;
    list-style-type: none;
    padding: 0; 
    margin: 64px 0 0; 
    .item {
      margin-bottom: 15px;
      a {
        text-decoration: none;
        font-size: var(--fs-text);
        display: flex;
        align-items: center;
        text-align: start;
        color: var(--text-6);
      }
    }
  }
  .newsletter {
    margin: 64px 0 0;
    .desc {
      color: var(--text-6);
    }
    .form {
      margin: 25px 0 29px;
      display: flex;
      gap: 0.5rem;
      .text {
        width: 66%;
        /* margin-left: 5%; */
        /* color: #fff; */
      }
      .btn {
        width: 29%;
      }
      /* button {
        
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        background: var(--text-4);
        color: none;
        width: 30%;
        height: 57px;
        border: none;
        cursor: pointer;
        border-radius: 8px;
      }
      input {
        margin-left: 30px;
        width: 70%;
        height: 57px;
        border: 1px solid #c8c6c4;
        border-radius: 10px;
        background: none;
        color: var(--text-1s);
      } */
    }
  }

  .social-links {
    display: flex;
    flex-direction: row;
    .socials {
      width: 40%;
      .titlesochial {
        justify-content: center;
        font-size: var(--fs--22-14-16) !important;
      }
      .iconsochialmedia {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-sochial-footer {
          path {
            fill: var(--text-6);
            stroke: var(--text-6);
            background: red;
          }
        }
      }
    }
    .certificates {
        display: flex;
        width: 55%;
        margin-right: 5%;
        .desc {
          width:125px !important;
          z-index:999999;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }
        a {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: #292d32;
          height: 107px;
          width: 50%;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: 90%;
            margin: 0 10% 0 0;
            background: #c4c4c4;
            border-radius: 12px;
          }
        }
      }
  }
  @media screen and (max-width: 1400px) {
    .column-2 {
      grid-column: 6/9;
      flex-direction: column;
    }
    .column-3 {
      grid-column: 9/14;
    }
    .items-link {
      flex-grow: 0;
      :last-child {
        margin: 0;
        .title {
          display: none;
        }
      }
    }
  }


  @media screen and (max-width: 1000px) {
    --fs-title: 16px;
    --fs-text: 12px;
    .top-part{
      grid-template-columns:
        14px repeat(6, calc(calc(calc(100% - 60px) / 6) - calc(80px / 6)))
        14px;
      grid-column-gap: 16px;
    }
    .column-1 {
      grid-column: 2/5;
    }
    .column-2 {
      grid-column: 5/6;
    }
    .column-3 {
      grid-column: 6/8;
    }
    .items-link {
      margin: 167px 0 0; 
    }
    .social-links {
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      .socials {
        width: 90%;
      }
      .certificates {
        width: 100%;
        margin: 2rem 0 29px;
        a {
          div{
            margin: 0 0 0 10%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    --fs-title: 16px;
    --fs-text: 12px;
    .top-part{
      margin: 0 16px;
      display: flex;
      flex-direction: column;
    }
    .pod-desc {
      margin: 32px 0 0; 
    }
    .items-link {
      margin: 26px 0 0; 
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: calc(100% / 3);
      }
      .title {
        width: 100%;
      }
    }
    .newsletter {
      margin: 26px 0 0;
    }
    .social-links {
      flex-grow: 1;
      flex-direction: row;
      justify-content: flex-end;
      .socials {
        width: 50%;
        .iconsochialmedia {
          justify-content: flex-start;
          .icon-sochial-footer {
            width: 2rem;
          }
          a {
            margin: 0.5rem;
            :first-child{
              margin: 0 0 0 0.5rem;
            }
          }
        }
      }
      .certificates {
        width: 50%;
        margin: 0 0 29px;
        a {
          div{
            margin: 0 0 0 10%;
          }
        }
      }
    }
  }
`;


const NewsLetter_Mutation = gql`
  mutation Newsletter(
    $Email: String!
  ) {
    newsletter(
      Email: $Email
    ) {
      status
      data
    }
  }
`;

function Footer(props) {
  const [email , setEmail] = useState('')
  const [AddEmail, addEmailState] = useMutation(NewsLetter_Mutation,{
      onCompleted: updateDataAdd,
      fetchPolicy: 'network-only'
  });

  function updateDataAdd (data) {
    console.log(data);
      if(data.newsletter.status){
          props.updateError({
              text: data.newsletter.data,
              time: 3,
              type: 'confirm'
          });
      } else {
        // error
        props.updateError({
            text: data.newsletter.data,
            time: 3,
            type: 'error'
        });
      }
  }

  function sendData() {
    // send updated podcast data
    AddEmail({
      variables: {
        Email: email,
      },
    });
  }

  const onChangeForm = (event) => {
    switch (event.target.name) {
        case 'email':
            setEmail(event.target.value);
            break;
        default: 
            break;
    }
  }


  const checkData = () => {
    if (
      email
    ) {
      return true;
    } else {
      return false;
    }
  };


  const makeNewsLetter = () => {
    return (
      <div className="newsletter">
        <p className="title" style={{ marginBottom: "0.5rem" }}>
          {Language[lang].footerNewsTilte}
        </p>
        <p className="desc" style={{ width: "100%" }}>
          {Language[lang].footerNewsDesc}
        </p>
        <div className="form">
          <div className="text">
            <TextInputWTitle 
              className={' dark'}
              onchange={onChangeForm}
              name={"email"}
              value={email}
              error={''}
              title={Language[lang].footerNewsEmail}
            />
          </div>
          <div className="btn">
            <TextBtn
              className={'dark'}
              type={"button"}
              text={Language[lang].footerNewsSend}
              onclick={sendData}
              loading={addEmailState.loading}
              disabled={!checkData() || addEmailState.loading}
            />
          </div>
        </div>
      </div>
    )
  }


  const makeSocialAndLinks = () => {
    return (
      <div className="social-links">
        <div className="socials">
          <p
            style={{
              marginBottom: "15px ",
              fontSize: "12px",
            }}
          >
            {Language[lang].footerSocials}
          </p>
          <div className="iconsochialmedia">
            <a href="https://twitter.com/podkadeh">
              <IconTwitter className={"icon-sochial-footer"} />
            </a>
            <a href="https://www.instagram.com/podkadeh">
              <IconInstagram className={"icon-sochial-footer"} />
            </a>
            <a href="https://www.linkedin.com/company/podkadeh">
              <IconLinkdin className={"icon-sochial-footer"} />
            </a>
          </div>
        </div>
        <div className="certificates">
          {/* <a href="to" className="desc">
            <div></div>
          </a> */}
          <div className="Samandehi-logo-wrapper desc"><img referrerPolicy="origin" id="jxlzjzpejxlzsizpnbqeoeuk" style={{cursor:"pointer"}} onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=171928&p=rfthjyoerfthpfvluiwkmcsi", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")} alt="logo-samandehi" src="https://logo.samandehi.ir/logo.aspx?id=171928&amp;p=nbpdyndtnbpdbsiyodrfaqgw"/></div>
          <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=350460&amp;Code=B9HAZWTst0AaldSJkslE"><img referrerPolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=350460&amp;Code=B9HAZWTst0AaldSJkslE" alt="" style={{cursor: "pointer"}} id="B9HAZWTst0AaldSJkslE"/></a>
        </div>
      </div>
    )
  }


  return (
    <Foter className={"footer-"+Language[lang].dir}>
      <div className="top-part">
        <div className="column-1 flex-column">
          <div className="pod-desc">
              {
                (Language[lang].dir === "ltr") ? (
                  <img src={logoen} alt="logopodkadehfooter"/>
                ) : (
                  <img src={logopodkadehfooter} alt="logopodkadehfooter" />
                )
              }
            <div className="desc">
              {Language[lang].footerDesc}
            </div>
          </div>
          {(window.innerWidth <= 1000 && window.innerWidth >= 600) ? makeNewsLetter() : ''}
        </div>
        <div className="column-2 flex-row">
          <ul className="items-link">
            <li className="item title">{Language[lang].footerFast} </li>
            <li className="item">
              <Link to="/"> {Language[lang].footerBesaz}</Link>
            </li>
            <li className="item">
              <Link to="/podcasts">آرشیو پادکست</Link>
            </li>
            {/* <li className="item">
              <Link to="/besaz-landing">پادکده کجاست</Link>
            </li> */}
            <li className="item">
              <a href="https://shenidesho.com">{Language[lang].footerSponser}</a>
            </li>
            
            {/* {( window.innerWidth >= 600) ? (
              <li className="item">
                <a href="https://podkadeh.com/podcaster">{Language[lang].footerPanel}</a>
              </li>
            ) : ''} */}
          </ul>
          <ul className="items-link">
            <li className="item title">{Language[lang].footerFast} </li>
            <li className="item">
              <a href="https://podkadeh.com/blog/">{Language[lang].blogs}</a>
            </li>
            {/* {( window.innerWidth < 600) ? (
              <li className="item">
                <a href="https://podkadeh.com/podcaster">{Language[lang].footerPanel}</a>
              </li>
            ) : ''} */}
            {/* <li className="item">
              <Link to="/termsofservice">شرایط و قوانین </Link>
            </li> */}
            <li className="item">
              <Link to="/aboutus">{Language[lang].footerAbout}</Link>
            </li>
            <li className="item">
              <Link to="/contactus">{Language[lang].footerContactus}</Link>
            </li>
          </ul>
        </div>
        <div className="column-3 flex-column">
          {(window.innerWidth > 1000 || window.innerWidth < 600) ? makeNewsLetter() : ''}
          {makeSocialAndLinks()}
        </div>
      </div>
      <div className="bottom-part">
        {Language[lang].footerCopy}
      </div>
    </Foter>
  )
  // if (window.innerWidth > 1000) {
  //   return (
  //     <Foter>
  //       <div className="desc-pod">
  //         <img src={logopodkadehfooter} alt="logopodkadehfooter" />
  //         <desc>
  //           تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با صنعت
  //           پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی جذاب،
  //           تجربه لذت‌بخشی در استفاده از آن داشته باشند.
  //         </desc>
  //       </div>
  //       <div className="access">
  //         <div className="access-right">
  //           <ul className="items">
  //             <li className="item title">دسترسی سریع </li>
  //             <li className="item">
  //               <Link to="/"> بشنو</Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/besaz-landing">پادکستر</Link>
  //             </li>
  //             <li className="item">
  //               <a href="https://shenidesho.com">اسپانسر</a>
  //             </li>
  //           </ul>
  //         </div>
  //         <div className="access-left">
  //           <ul className="items">
  //             <li className="item title">دسترسی سریع </li>
  //             <li className="item">
  //               <Link to="/termsofservice">قوانین و شرایط همکاری</Link>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //       <div className="newsletters">
  //         <div>
  //           <p className="title" style={{ marginBottom: "0.5rem" }}>
  //             خبرنامه - عضو شوید
  //           </p>
  //           <p className="desc" style={{ width: "100%" }}>
  //           برای دریافت آخرین اخبار دنیای پادکست و پیشنهادات ویژه پادکده و اطلاع از بروزرسانی‌های اپلیکیشن ایمیل خود را وارد کنید.
  //           </p>
  //           <form>
  //             <input />
  //             <button>ثبت</button>
  //           </form>
  //         </div>
  //         <div className="content">
  //           <div className="sochial">
  //             <p
  //               style={{
  //                 marginBottom: "15px ",
  //                 fontSize: "12px",
  //               }}
  //             >
  //               پادکده در شبکه اجتماعی
  //             </p>
  //             <div className="iconsochialmedia">
  //               <Link to="/">
  //                 <IconTwitter className={"icon-sochial-footer"} />
  //               </Link>
  //               <Link to="/">
  //                 <IconLinkdin className={"icon-sochial-footer"} />
  //               </Link>
  //               <Link to="/">
  //                 <IconInstagram className={"icon-sochial-footer"} />
  //               </Link>
  //             </div>
  //           </div>
  //           <div className="sertifikat">
  //             <a href="to" className="desc">
  //               <div>نماد الکترونیک</div>
  //             </a>
  //             <Link to="/" className="desc">
  //               <div>نماد</div>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="nav-download-app-footer">
  //         <p className="title-navdn-footer"> پادکده را از کجا دانلود کنیم؟ </p>
  //         <div className="downloadapp">
  //           <ul className="items">
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconAndroid />
  //                 دریافت اندروید
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconApple />
  //                 دریافت ios
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconBazar />
  //                 دریافت از بازار
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconSibche />
  //                 دریافت از سیبچه
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconSibApp />
  //                 دریافت از سیب‌اپ
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconMyket />
  //                 دریافت از مایکت
  //               </Link>
  //             </li>
  //             <li className="item">
  //               <Link to="/" className="deactive-link">
  //                 <IconAnardoni />
  //                 دریافت از اناردونی
  //               </Link>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </Foter>
  //   );
  // } else if (window.innerWidth > 600) {
  //   return (
  //     <Foter>
  //       <div className="top">
  //         <div className="right">
  //           <div className="desc-pod">
  //             <img src={logopodkadehfooter} alt="logopodkadehfooter" />
  //             <desc>
  //               تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با
  //               صنعت پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی
  //               جذاب، تجربه لذت‌بخشی در استفاده از آن داشته باشند.
  //             </desc>
  //           </div>
  //           <div className="forms">
  //             <p className="title" style={{ marginBottom: "0.5rem" }}>
  //               خبرنامه - عضو شوید
  //             </p>
  //             <p className="desc">
  //             برای دریافت آخرین اخبار دنیای پادکست و پیشنهادات ویژه پادکده و اطلاع از بروزرسانی‌های اپلیکیشن ایمیل خود را وارد کنید.
  //             </p>
  //             <form>
  //               <input />
  //               <button>ثبت</button>
  //             </form>
  //           </div>
  //         </div>
  //         <div className="left">
  //           <div className="access">
  //             <ul className="items">
  //               <li className="title">دسترسی سریع </li>
  //               <li className="item">
  //                 <Link to="/"> بشنو</Link>
  //               </li>
  //               <li className="item">
  //                 <Link to="/besaz-landing">پادکستر</Link>
  //               </li>
  //               <li className="item">
  //                 <a href="https://shenidesho.com">اسپانسر</a>
  //               </li>
  //               {/* <li className="item">
  //                 <Link to="/">بلاگ</Link>
  //               </li> */}
  //               {/* <li className="item">
  //                 <Link to="/">حریم خصوصی</Link>
  //               </li> */}
  //               <li className="item">
  //                 <Link to="/termsofservice">قوانین و شرایط همکاری</Link>
  //               </li>
  //             </ul>
  //           </div>
  //           <div className="sertifikat-and-sochial">
  //             <div className="sochialmedia">
  //               <p>پادکده در شبکه اجتماعی</p>
  //               <div className="iconsochialmedia">
  //                 <Link to="/">
  //                   <IconTwitter />
  //                 </Link>
  //                 <Link to="/">
  //                   <IconLinkdin />
  //                 </Link>
  //                 <Link to="/">
  //                   <IconInstagram />
  //                 </Link>
  //               </div>
  //             </div>
  //             <div className="sertifikat">
  //               <a href="to" className="desc">
  //                 <div>نماد الکترونیک</div>
  //               </a>
  //               <Link to="/" className="desc">
  //                 <div>نماد</div>
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="botoom">
  //         <p>پادکده را از کجا دانلود کنیم؟</p>

  //         <div className="downloadapp">
  //           <ul className="items">
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //           </ul>
  //           <ul className="items">
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //             <li className="item">
  //               <DownloadAppBtn
  //                 style={{ gridColumn: "8/10" }}
  //                 className={" deactive"}
  //                 svg={<IconBazar />}
  //                 Children={"دریافت از بازار"}
  //               ></DownloadAppBtn>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </Foter>
  //   );
  // } else {
  //   return (
  //     <Foter>
  //       <div className="container-footer-mobile">
  //         <div className="desc-pod">
  //           <img src={logopodkadehfooter} alt="logopodkadehfooter" />
  //           <desc>
  //             تیم پادکده با استفاده از تکنولوژی‌های روز دنیا و آشنایی کامل با
  //             صنعت پادکست، تلاش می‌کند تا تمامی مخاطبان و کاربران این سرگرمی
  //             جذاب، تجربه لذت‌بخشی در استفاده از آن داشته باشند.
  //           </desc>
  //         </div>
  //         <div className="access-mobile">
  //           <p> دسترسی سریع </p>
  //           <ul className="items">
  //             <li className="item">
  //               <Link to="/"> بشنو</Link>
  //             </li>
  //             <li className="item">
  //                 <Link to="/besaz-landing">پادکستر</Link>
  //             </li>
  //             <li className="item">
  //               <a href="https://shenidesho.com">اسپانسر</a>
  //             </li>
  //           </ul>
  //           <ul>
  //             {/* <li className="item">
  //               <Link to="/">بلاگ</Link>
  //             </li> */}
  //             <li className="item">
  //               <Link to="/termsofservice">قوانین و شرایط همکاری</Link>
  //             </li>
  //             {/* <li className="item">
  //               <Link to="/">حریم خصوصی</Link>
  //             </li> */}
  //           </ul>
  //         </div>
  //         <div className="newsletters-mobile">
  //           <div className="forms">
  //             <p className="title" style={{ marginBottom: "0.5rem" }}>
  //               خبرنامه - عضو شوید
  //             </p>
  //             <p className="desc">
  //             برای دریافت آخرین اخبار دنیای پادکست و پیشنهادات ویژه پادکده و اطلاع از بروزرسانی‌های اپلیکیشن ایمیل خود را وارد کنید.
  //             </p>
  //             <form>
  //               <input />
  //               <button>ثبت</button>
  //             </form>
  //           </div>
  //           <div className="content"></div>
  //         </div>
  //         <div className="downlaodapp-mobile">
  //           <p>پادکده را از کجا دانلود کنیم؟</p>
  //           <div className="nav-dn-content">
  //             <Link to="/">دانلود اپلیکیشن</Link>
  //           </div>
  //         </div>
  //         <div className="foooter-footer-mobile">
  //           <div className="sochialmedia">
  //             <p className="titlesochial">پادکده در شبکه اجتماعی</p>
  //             <div className="iconsochialmedia">
  //               <Link to="/">
  //                 <IconTwitter />
  //               </Link>
  //               <Link to="/">
  //                 <IconLinkdin />
  //               </Link>
  //               <Link to="/">
  //                 <IconInstagram />
  //               </Link>
  //             </div>
  //           </div>
  //           <div className="sertifikat">
  //             <a href="to" className="desc">
  //               <div>نماد الکترونیک</div>
  //             </a>
  //             <Link to="/" className="desc">
  //               <div>نماد</div>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </Foter>
  //   );
  // }
}

export default Footer;
