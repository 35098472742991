import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AutoAnimationTitle from './AutoAnimationTitle';
import Arrow from '../../Assets/svg/left-arrow.svg'
import EndObserver from './EndObserver';


const StyleCss = styled(Link)`
    direction: rtl !important;
    width: calc(100% - 4rem);
    overflow: hidden;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 1rem 2rem;
    color: inherit;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    
    &.active {
        background: linear-gradient(0deg, rgba(234, 92, 0, 0.1), rgba(234, 92, 0, 0.1));
        .image {
            width: 54px;
            border: 1px solid #EA5C00;
        }
        .title {
            color: #EA5C00;
        }
    }
    &:hover {
        .title {
            color: #EA5C00;
        }
    }
    a{
        width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--text-2);
        :hover{
            color: var(--text-2);
        }
    }
    .image {
        width: 56px;
        aspect-ratio: 1;
        border-radius: 4px;
        /* border: 1px solid #EA5C00; */
        overflow: hidden;
        img{
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }
    .title {
        font-size: 1rem;
        font-weight: 700;
        text-align: right;
    }
    
    .expand {
        flex-grow: 1;
    }
    .data {
        color: #5E5E5E;
        display: flex;
        .time {
            font-size: 0.87rem;
            font-weight: 500;
            white-space: nowrap;
        }
    }
    @media screen and (max-width: 850px) {
        width: calc(100% - 2rem);
        padding: 1rem 1rem;
    }
`
function EpisodeCartList(props) {
    const [show, setShow] = useState(false);
    let time = new Date(props.podcast)
    let timeString = time.getHours() + ':' + time.getMinutes() + ' ' +time.getFullYear() + '/' + (time.getMonth()+1) + '/' + time.getDate() ;
    return (
        <StyleCss to={props.baseLink} className={(props.active ? "active" :"")}>
            <div className="image">
                {
                    show ? (
                        <img src={props.image} alt={props.title} onLoad={(e) => e.target.style.opacity = 1}/>
                    ) : (
                        <EndObserver func={() => setShow(true)}/>
                    )
                }
            </div>
            <AutoAnimationTitle title={props.title}/>
            <div className='data'>
                <div className="time">{timeString}</div>
            </div>
        </StyleCss>
    );    
}
export default EpisodeCartList;