import React from "react";
export const IconClose = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41406"
        width="20"
        height="2"
        rx="1"
        transform="rotate(45 1.41406 0)"
        fill="white"
      />
      <rect
        y="14.1426"
        width="20"
        height="2"
        rx="1"
        transform="rotate(-45 0 14.1426)"
        fill="white"
      />
    </svg>
  );
};
