import React from 'react';
export default function CloseSearch(props) {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16">
		<path class="st0" d="M10.1,10.6c-0.1,0-0.2,0-0.3-0.1L5.5,6.2C5.4,6,5.4,5.7,5.5,5.5c0.2-0.2,0.5-0.2,0.6,0l4.3,4.3
					c0.2,0.2,0.2,0.5,0,0.6C10.4,10.5,10.3,10.6,10.1,10.6z"/>
		<path class="st0" d="M5.9,10.6c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.5,0-0.6l4.3-4.3c0.2-0.2,0.5-0.2,0.6,0c0.2,0.2,0.2,0.5,0,0.6
					l-4.3,4.3C6.1,10.5,6,10.6,5.9,10.6z"/>
		</svg>
	)
}
