export const IconHelp = ({ style, className }) => {
  // style={style}
  return (
    <svg
      style={style}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.00183 22H15.0018C20.0018 22 22.0018 20 22.0018 15V9C22.0018 4 20.0018 2 15.0018 2H9.00183C4.00183 2 2.00183 4 2.00183 9V15C2.00183 20 4.00183 22 9.00183 22Z" stroke="#323130" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7419 14.6C12.735 14.1339 12.6828 13.767 12.5854 13.4991C12.488 13.2278 12.288 12.9287 11.9854 12.6017L11.2185 11.8087C10.8915 11.4365 10.728 11.0383 10.728 10.6139C10.728 10.2035 10.8358 9.88174 11.0515 9.6487C11.2637 9.41565 11.575 9.29913 11.9854 9.29913C12.3854 9.29913 12.7071 9.40522 12.9506 9.61739C13.1906 9.82957 13.3106 10.113 13.3106 10.4678H14.3958C14.3889 9.83478 14.1645 9.32348 13.7228 8.93391C13.2776 8.54435 12.6985 8.34956 11.9854 8.34956C11.248 8.34956 10.6741 8.54783 10.2637 8.94435C9.84976 9.34087 9.6428 9.88522 9.6428 10.5774C9.6428 11.2626 9.95932 11.9374 10.5924 12.6017L11.2289 13.233C11.5141 13.5496 11.6567 14.0052 11.6567 14.6H12.7419ZM12.7889 16.4574C12.7889 16.28 12.735 16.1322 12.6271 16.0139C12.5193 15.8957 12.3593 15.8365 12.1471 15.8365C11.9385 15.8365 11.7802 15.8957 11.6724 16.0139C11.5611 16.1322 11.5054 16.2783 11.5054 16.4522C11.5054 16.6296 11.5611 16.7757 11.6724 16.8904C11.7802 17.0052 11.9385 17.0626 12.1471 17.0626C12.3593 17.0626 12.5193 17.0052 12.6271 16.8904C12.735 16.7757 12.7889 16.6313 12.7889 16.4574Z" fill="#323130"/>
    </svg>
  );
};
